import { GET_ALL_STANDINGS, CLEAR_STANDINGS } from '../actions/actionTypes';

const initialState = {
    standings: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_STANDINGS:
            return {
                ...state,
                standings: action.standings
            }
        
            case CLEAR_STANDINGS: {
                return {
                    ...state,
                    standings: []
                }
            }
        default:
            return state;
    }
}
import thunk from 'redux-thunk';
import loginReducer from '../Login/reducers/user';
import toastsReducer from "Toasts/reducers/toasts"
import spinnerReducer from "Spinner/reducers/spinner";
import leaguesReducer from "Pages/Leagues/reducers/leagues";
import matchesReducer from "Pages/Matches/reducers/matches";
import usersReducer from "Pages/Users/reducers/users";
import gameWeeksReducer from "Pages/GameWeeks/reducers/gameWeeks";
import advertisementReducer from "Pages/Advertisements/reducers/advertisements";
import gameRoomReducer from "Pages/GameRooms/reducers/gameRooms";
import gameStandingsReducer from "Pages/GameStandings/reducers/gameStandings";
import specialRoomsReducer from "Pages/SpecialRooms/reducers/specialRooms";
import notificationsReducer from "Pages/Notifications/reducers/notifications";

import { createStore, combineReducers, applyMiddleware, compose } from "redux";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    spinner: spinnerReducer,
    user: loginReducer,
    toasts: toastsReducer,
    leagues: leaguesReducer,
    games: matchesReducer,
    users: usersReducer,
    weeks: gameWeeksReducer,
    rooms: gameRoomReducer,
    standings: gameStandingsReducer,
    specialRooms: specialRoomsReducer,
    notifications: notificationsReducer,
    advertisement: advertisementReducer,
});



export default function configureStore(initialState) {
    return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
}
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/icons/List";

// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";

import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx"
import OutlinedSelect from "UI/OutlinedSelect/OutlinedSelect";
import Pagination from "UI/Pagination/Pagination";
import ImageIcon from 'UI/ImageIcon/ImageIcon'
import TooltipButton from 'UI/CustomButtons/TooltipButton/TooltipButton'

import { getAllLeagues, clearAllLeagues } from 'Pages/Leagues/actions/leagues.js';
import { getAllRoomsForLeague, clearRooms } from "Pages/GameRooms/actions/gameRooms.js";
import { connect } from 'react-redux';
import { darkBlueColor } from 'utils/Constants'
import { IMAGE_TINY } from "../../../utils/Constants";

const styles = {
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    selectItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    badgeTitle: {
        marginLeft: "10px"
    }
};

class GameRoomsList extends React.Component {

    state = {
        infoModalOpen: false,
        page: 1,
        lastPage: 1,
        leagueId: ""
    }

    componentWillMount() {
        this.props.clearAllLeagues();
        if (!(this.props.leagues !== undefined && this.props.leagues > 0)) {
            this.props.getAllLeagues(1);
        }
    }

    componentWillUnmount() {
        this.props.clearAllLeagues();
        this.props.clearRooms();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.rooms !== this.props.rooms) {
            this.setState({
                page: nextProps.rooms.current_page,
                lastPage: nextProps.rooms.last_page,
            })
        }
    }

    handleSelect = (id) => {
        this.setState({
            ...this.state,
            leagueId: id
        });
    }

    infoHandler = (id) => {
        this.props.history.push("/roomInfo/" + id + "/" + this.state.leagueId + "/" + this.props.rooms.data.find(t => t.id === id).name);
    }

    filterHandler = (page) => {
        this.props.getAllRoomsForLeague({ id: this.state.leagueId, page: page });
    }

    render() {
        const { classes } = this.props;

        let mappedLeagues = [];
        const leagues = this.props.allLeagues;
        if (leagues !== undefined && leagues.length > 0) {
            mappedLeagues = leagues.map(t => {
                return {
                    valueData: [
                        <div key={t.id} className={classes.selectItem}>
                            <ImageIcon big={IMAGE_TINY} image={t.image[0]} />
                            <span className={classes.badgeTitle}>{t.name}</span>
                        </div>
                    ],
                    id: t.id
                };
            });
        }

        let mappedGameRooms = [];
        const rooms = this.props.rooms.data;
        if (rooms !== undefined && rooms.length > 0) {
            mappedGameRooms = rooms.map(t => {
                const isActive = t.is_active === 1 ? "Active" : "Not Active"
                const info = <TooltipButton labelName="Info" onClick={() => this.infoHandler(t.id)}> <List /></TooltipButton>
                return {
                    tableData: [t.name, t.room_code, t.max_players, isActive, t.owner, t.players_in_room, info],
                    id: t.id
                };
            });
        }

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="warning" className={classes.card}>
                            <h4 className={classes.cardTitle}>Game rooms per league:</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer style={{ alignItems: "center" }}>
                                <GridItem xs={12} sm={6} md={6}>
                                    <OutlinedSelect
                                        labelName="League"
                                        selectData={mappedLeagues}
                                        value={this.state.leagueId}
                                        onChange={(event) => this.handleSelect(event)}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={3}>
                                    <Button
                                        round
                                        onClick={() => this.filterHandler(1)}
                                        color="success">
                                        Filter
                                        </Button>
                                </GridItem>
                            </GridContainer>
                            <TableWithActionButtons
                                tableHeaderColor="gray"
                                tableHead={["Name", "Room Code", "Max Players", "Status", "Owner", "Players in Room", "   "]}
                                tableData={mappedGameRooms}
                            />
                            <GridItem xs={12} sm={12} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
                                {this.state.lastPage > 1 ? <Pagination
                                    changePageTo={this.filterHandler}
                                    currentPage={this.state.page}
                                    totalPages={this.state.lastPage}
                                    color="warning"
                                /> : null}
                            </GridItem>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
        leagues: state.leagues.leagues,
        rooms: state.rooms.rooms,
        allLeagues: state.leagues.allLeagues,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllLeagues: (page) => dispatch(getAllLeagues(page, true)),
        getAllRoomsForLeague: (id) => dispatch(getAllRoomsForLeague(id)),
        clearRooms: () => dispatch(clearRooms()),
        clearAllLeagues: () => dispatch(clearAllLeagues())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GameRoomsList));

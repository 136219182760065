import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CardFooter from "vendor/components/Card/CardFooter.jsx";
import { getUsersEmails, sendUserNotification } from "Pages/Notifications/actions/notifications"
import { connect } from 'react-redux';
import { infoColor } from "vendor/assets/jss/material-dashboard-react.jsx";
import ConfirmModal from "UI/ConfirmModal/ConfirmModal"
import AutoSuggestField from "UI/AutoSuggestField/AutoSuggestField"

class UserNotificationsForm extends React.Component {

    state = {
        body: "",
        email: "",
        id: "",
        confirmModalOpen: false
    }

    handleChangeInput = (name) => event => {

        this.setState({
            ...this.state,
            [name]: event.target.value
        });
    };

    confirmSendNotification = () => {
        this.setState({
            confirmModalOpen: true,
            confirmMessage: "Are you sure you want to send a notification to this user?",
        })
    }

    closeConfirmModalHandler = (id) => {
        this.setState({ confirmModalOpen: false });
    }

    sendNotification = () => {
        const data = {
            body: this.state.body,
            user_id: this.props.usersEmails.find(t=> t.email === this.state.email).id
        };
        this.props.sendUserNotification(data);
        this.closeConfirmModalHandler()
    }

    handleChange = name => (event, { newValue }) => {
        this.props.getUsersEmails({email: newValue});
        this.setState({
            ...this.state,
            [name]: newValue,
        });
    };


    render() {
        const { classes } = this.props;
        return (
            <div>
                <ConfirmModal
                    open={this.state.confirmModalOpen}
                    close={this.closeConfirmModalHandler}
                    message={this.state.confirmMessage}
                    yesButtonClisk={this.sendNotification} />
                <GridContainer className={classes.container}>
                    <Card className={classes.card}>
                        <CardHeader color="info" className={classes.cardHeader}>
                            <h4 className={classes.cardTitle}>Notification to specific user</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12} className={classes.grid}>
                                    <AutoSuggestField
                                        value={this.state.email}
                                        suggestions={this.props.uiUserEmails}
                                        label={"User Email"}
                                        placeholder={"Search for user by email"}
                                        handleChange={this.handleChange("email")}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12} className={classes.grid}>
                                    <textarea
                                        className={classes.textarea}
                                        maxLength="120"
                                        type="text"
                                        id="body"
                                        name="body"
                                        placeholder="Enter notification for user"
                                        onChange={this.handleChangeInput("body")}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button
                                onClick={this.confirmSendNotification}
                                color="info">
                                Send
                            </Button>
                        </CardFooter>
                    </Card>
                </GridContainer>
            </div>
        );
    };
}

const styles = {

    textarea: {
        width: "100%",
        resize: "vertical",
        height: "5rem",
        outline: "none",
        padding: "1rem",
        border: "2px solid " + infoColor,
        borderRadius: "5px",
        color: "black",
        fontSize: "0.85rem",
        boxSizing: "border-box"
    },

    cardTitle: {
        color: "white",
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none"
    },
    card: {
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "0",
        flexBasis: "100%"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    cardHeader: {
        width: "100%",
    },
    grid: {
        margin: "1rem"
    }

};

const mapStateToProps = state => {

    return {
        usersEmails: state.notifications.usersEmails,
        uiUserEmails: state.notifications.uiUserEmails
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getUsersEmails: (data) => dispatch(getUsersEmails(data)),
        sendUserNotification: data => dispatch(sendUserNotification(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserNotificationsForm));

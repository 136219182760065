import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getAllLeagues = (page, shouldAppend) => {
    return dispatch => {
        axios.get('admin/leagues', { params: { page: page } })
            .then(res => {
                if (shouldAppend && res.data.data.last_page > 1) {
                    for (var i = 2; i <= res.data.data.last_page; i++) {
                        axios.get('admin/leagues', { params: { page: i } }).
                            then(res => {
                                dispatch(setAllLeagues(res.data.data));
                            })
                    }
                }
                dispatch(setAllLeagues(res.data.data));
            });
    };
};

export const clearAllLeagues = () => {
    return dispatch => dispatch({
        type: actionTypes.CLEAR_ALL_LEAGUES
    })
}

export const setAllLeagues = (leagues) => {
    return {
        type: actionTypes.GET_ALL_LEAGUES,
        leagues
    };
};

export const addLeague = (data) => {
    return dispatch => {
        axios.post("/league", data)
            .then(res => {
                dispatch(setLeagueAdded(res.data.data, data));
            });
    }
}

export const setLeagueAdded = (league, requestData) => {
    return {
        type: actionTypes.ADD_LEAGUE,
        league,
        requestData
    }
}

export const editLeague = (data) => {
    return dispatch => {
        axios.put("/league", data)
            .then(res => {
                dispatch(setLeagueEdited(res.data.data, data));
            });
    }
}

export const setLeagueEdited = (league, requestData) => {
    return {
        type: actionTypes.EDIT_LEAGUE,
        league,
        requestData
    }
}

export const getAllValidLeagues = () => {
    return dispatch => {
        axios.get('competitions')
            .then(res => {
                dispatch(setAllValidLeagues(res.data.data));
            });
    };
}

export const setAllValidLeagues = (apiLeagues) => {
    return {
        type: actionTypes.GET_ALL_AVAILABLE_LEAGUES,
        apiLeagues
    }
}

import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import { BASE_URL, IMAGE_TINY, IMAGE_REGULAR, IMAGE_LARGE } from "utils/Constants.js";
import default_image from 'assets/img/default_image.png';

class ImageIcon extends React.Component {

    render() {
        const { classes } = this.props;
        let big = this.props.big === null || this.props.big === undefined ? 1 : this.props.big;
        let classname = classes.logoIconBig;
        if (big === IMAGE_TINY) {
            classname = classes.logoIconSmall;
        } else if (big === IMAGE_REGULAR) {
            classname = classes.logoIconBig;
        } else if (big === IMAGE_LARGE) {
            classname = classes.logoIconExtraBig;
        }
        let url = "";
        if (this.props.image && this.props.image.url) {
            if (this.props.image.url.startsWith("http")) {
                url = this.props.image.url;
            } else {
                url = BASE_URL + this.props.image.url;
            }
        } else {
            url = default_image;
        }
        return (
            <img src={url}
                alt="Logo"
                className={classname} />

        );
    };

}

const styles = {
    logoIconSmall: {
        width: "1.9em",
        height: "1.9em",
        border: "2px solid #ccc",
        borderRadius: "50%",
        overflow: "hidden",
        transition: "all .2s"
    },
    logoIconBig: {
        width: "2.5em",
        height: "2.5em",
        border: "2px solid #ccc",
        borderRadius: "50%",
        overflow: "hidden",
        transition: "all .2s"
    },
    logoIconExtraBig: {
        width: "7em",
        height: "7em",
        border: "2px solid #ccc",
        borderRadius: "50%",
        overflow: "hidden",
        transition: "all .2s"
    }
};

export default withStyles(styles)(ImageIcon);

import { GET_ALL_USERS } from 'Pages/Users/actions/actionTypes';
import { GET_USER_ROOMS, GET_USER_INFO, GET_USER_PREDICTIONS, EDIT_USER_PREDICTION } from '../actions/actionTypes';


const initialState = {
    users: [],
    user: {}
}

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_ALL_USERS:
            return {
                ...state,
                users: action.data
            }
        case GET_USER_ROOMS:
            return {
                ...state,
                rooms: action.rooms
            }

            case GET_USER_INFO:
                return {
                    ...state,
                    user: action.user
                }

                case GET_USER_PREDICTIONS:
                    return {
                        ...state,
                        predictions: action.predictions
                    }

                    case EDIT_USER_PREDICTION: {
                        return {
                            ...state,
                            predictions: state.predictions.map(t => {
                                if (t.game_id === action.prediction.matchId) {
                                    return {...t, prediction: action.prediction.bet}
                                }
                                return {...t}
                            })
                        }
                    }
        default:
            return state;
    }
}
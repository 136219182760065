import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import CardFooter from "vendor/components/Dashboard/Card/CardFooter.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import { store } from 'index';
import { showNotification } from 'Toasts/actions/toasts';

import { editSpecialRoomPrediction } from 'Pages/SpecialRooms/actions/specialRooms.js';
import { connect } from 'react-redux';
import { darkBlueColor } from 'utils/Constants'
import CustomInput from "vendor/components/CustomInput/CustomInput";


const styles = {

    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    cardHeader: {
        width: "100%"
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    badgeTitle: {
        marginRight: "10px"
    },
    inputIconsColor: {
        color: "lightgray"
    },
};

class EditSpecialRoomPredictionModal extends React.Component {

    componentWillMount() {
        this.setState({
            user_id: this.props.user_id,
            game_id: this.props.game_id,
            room_id: this.props.room_id,
            prediction: this.props.prediction
        });
    }

    newBetHandler = (event) => {
        this.setState({
            prediction: event.target.value
        });
    }

    editPrediction = () => {
        const prediction = this.state.prediction;
        if (prediction !== "1" && prediction !== "2" && prediction !== "x" && prediction !== "X") {
            store.dispatch(showNotification(
                {
                    type: "error",
                    msg: "Bet must be one of 1 / 2 / x"
                }
    
            ));
        } else {
            this.props.editSpecialRoomPrediction(this.state);
            this.props.onClose();
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <GridContainer className={classes.container}>
                    <Card className={classes.card}>

                        <CardHeader color="warning" className={classes.cardHeader}>
                            <h4 className={classes.cardTitle}>Edit Prediction</h4>
                        </CardHeader>

                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid} style={{minWidth: "200px"}}>
                                    <CustomInput
                                        labelText="Prediction"
                                        id="prediction"
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{
                                            value: this.state.prediction,
                                            onChange: this.newBetHandler
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>

                        <CardFooter className={classes.cardFooter}>
                            <Button
                                onClick={this.editPrediction}
                                color="success">
                                Edit Prediction
                            </Button>
                        </CardFooter>
                    </Card>
                </GridContainer>
            </div>
        );

    }
}

const mapDispatchToProps = dispatch => {
    return {
        editSpecialRoomPrediction: (data) => dispatch(editSpecialRoomPrediction(data))
    }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(EditSpecialRoomPredictionModal));
import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Dropdown from "UI/Dropdown/Dropdown.js";
import headerLinksStyle from "./headerLinksStyle";

class HeaderLinks extends React.Component {
  state = {
    open: false
  };
  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  logoutHandler = () => {
    localStorage.clear();
    this.props.history.index = 0;
    this.props.history.replace('/');
  }

  render() {
    const { classes } = this.props;
    const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));

    return (
      <div>
        <div className={classes.manager}>
          <ListItem className={classes.listItem}>
            <Dropdown
              noLiPadding
              buttonText={((loggedUser && loggedUser != null) ? loggedUser.email : "Admin")}
              buttonProps={{
                className: classes.navLink,
                color: "transparent"
              }}
              buttonIcon={Person}
              dropdownList={[<Link onClick={this.logoutHandler} to="/" className={classes.dropdownLink}> Log out </Link>]} />
          </ListItem>
        </div>



      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(HeaderLinks);

import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getAllRoomsForLeague = (data) => {
    return dispatch => {
        axios.get('/admin/rooms/' + data.id, { params: { page: data.page } } )
            .then(res => {
                dispatch(setAllRoomsForLeague(res.data.data));
            });
    };
};

export const setAllRoomsForLeague = (rooms) => {
    return {
        type: actionTypes.GET_ALL_ROOMS,
        rooms: rooms
    };
};

export const getRoomInfo = (data) => {
    return dispatch => {
        axios.get('/room/info/' + data.roomId, {params: {league_id: data.leagueId, page: data.page}})
        .then(res => {
            dispatch(setRoomInfo(res.data.data));
        })
    }
}

export const setRoomInfo = (room) => {
    return {
        type: actionTypes.GET_ROOM_INFO,
        room
    }
}

export const getUserInRoomInfo = (data) => {
    return dispatch => {
        axios.get("/user/room", {params: {user_id: data.userId, league_id: data.leagueId, room_id: data.roomId, page: data.page }})
        .then(res => {
            dispatch(setUserInRoomInfo(res.data.data));
        });
    }
}

export const setUserInRoomInfo = (user) => {
    return {
        type: actionTypes.GET_USER_IN_ROOM_INFO,
        user
    }
}

export const clearRooms = () => {
    return {
        type: actionTypes.CLEAR_ROOMS
    }
}
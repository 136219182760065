import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Edit from '@material-ui/icons/Edit';
import ShuffleIcon from '@material-ui/icons/Shuffle';
// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import TooltipButton from 'UI/CustomButtons/TooltipButton/TooltipButton'
import CustomModal from "UI/CustomModal/CustomModal"
import ImageIcon from 'UI/ImageIcon/ImageIcon'
import { getAllFilteredUsers } from 'Pages/Users/actions/users.js';
import { connect } from 'react-redux';
import { darkBlueColor, convertDate } from 'utils/Constants'

import OutlinedInput from "UI/OutlinedInput/OutlinedInput";
import Email from "@material-ui/icons/Email";
import PersonOutline from "@material-ui/icons/PersonOutline";
import Pagination from "UI/Pagination/Pagination";
import Button from "vendor/components/CustomButtons/Button.jsx";
import EditUserModal from "./EditUserModal";

const styles = {

    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    badgeTitle: {
        marginRight: "10px"
    },
    inputIconsColor: {
        color: "lightgray"
    },
};

class UsersList extends React.Component {

    state = {
        editModalOpen: false,
        lastPage: 1,
        page: 1,
        first_name: "",
        email: "",
        edit_id: null
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.users !== this.props.users) {
            this.setState({
                page: nextProps.users.current_page,
                lastPage: nextProps.users.last_page,
            })
        }
    }

    componentWillMount() {
        this.filterHandler(1);
    }

    handleChangeInput = (name) => event => {
        this.setState({
            ...this.state,
            [name]: event.target.value
        });
    };

    clearFilters = () => {
        let data = {
            page: 1,
            first_name: "",
            email: ""
        };

        this.setState({
            ...this.state,
            page: 1,
            first_name: "",
            email: ""
        });

        this.props.getAllFilteredUsers(data);
    }

    filterHandler = (page) => {
        let data = {
            page,
            first_name: this.state.first_name === "" ? null : this.state.first_name,
            email: this.state.email === "" ? null : this.state.email
        };
        this.props.getAllFilteredUsers(data);
    }

    infoHandler = (id) => {
        const user = this.props.users.data.find(t => t.id === id);
        this.props.history.push("/userInfo/" + id + "/" + user.first_name + " " + user.last_name);
    }

    editHandler = (id) => {
        this.setState({
            editModalOpen: true,
            edit_id: id
        });
    }

    predictionsHandler = (id) => {
        const user = this.props.users.data.find(t => t.id === id);
        this.props.history.push("/predictions/" + id + "/" + user.first_name + " " + user.last_name);
    }

    closeEditModal = () => {
        this.setState({
            editModalOpen: false,
            edit_id: null
        });
    }

    render() {
        const { classes } = this.props;

        let mappedUsers = [];
        if (this.props.users && this.props.users.data && this.props.users.data.length > 0) {
            mappedUsers = this.props.users.data.map(t => {
                const avatar = <ImageIcon image={t.image} />;
                const name = t.first_name + " " + t.last_name;
                const gender = t.gender ? t.gender.name : null;
                const nationality = t.nationality ? t.nationality.name : null;
                const rooms = <TooltipButton labelName="Rooms" onClick={() => this.infoHandler(t.id)}> <SupervisorAccountIcon /></TooltipButton>
                const edit = <TooltipButton labelName="Edit" onClick={() => this.editHandler(t.id)}> <Edit /></TooltipButton>
                const predictions = <TooltipButton labelName="Predictions" onClick={() => this.predictionsHandler(t.id)}> <ShuffleIcon /></TooltipButton>
                return {
                    tableData: [avatar, name, t.email, convertDate(t.created_at), gender, nationality, rooms, edit, predictions],
                    id: t.id
                };
            });
        }

        return (
            <div>
                <CustomModal open={this.state.editModalOpen} close={this.closeEditModal}>
                    {<EditUserModal
                        userId={this.state.edit_id}
                        onClose={this.closeEditModal}
                    />}
                </CustomModal>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>Users: {this.props.users.total} </h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <OutlinedInput
                                            fullWidth={true}
                                            icon={<PersonOutline className={classes.inputIconsColor} />}
                                            labelName="Name"
                                            value={this.state.first_name}
                                            onChange={this.handleChangeInput("first_name")} />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <OutlinedInput
                                            fullWidth={true}
                                            icon={<Email className={classes.inputIconsColor} />}
                                            labelName="Email"
                                            value={this.state.email}
                                            onChange={this.handleChangeInput("email")} />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Button
                                            round
                                            onClick={() => { this.filterHandler(1) }}
                                            color="success">
                                            Filter
                                        </Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Button
                                            round
                                            onClick={this.clearFilters}
                                            color="danger">
                                            Clear filters
                                        </Button>
                                    </GridItem>

                                </GridContainer>
                                <TableWithActionButtons
                                    tableHeaderColor="gray"
                                    tableHead={["   ", "Name", "Email", "Created At", "Gender", "Nationality", "   ", "   ", "   "]}
                                    tableData={mappedUsers}

                                />
                            </CardBody>
                            <GridItem xs={12} sm={12} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {this.state.lastPage > 1 ? <Pagination
                                    changePageTo={this.filterHandler}
                                    currentPage={this.state.page}
                                    totalPages={this.state.lastPage}
                                    color="warning"
                                /> : null}
                            </GridItem>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );

    }
}

const mapStateToProps = state => {

    return {
        users: state.users.users
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllFilteredUsers: (data) => dispatch(getAllFilteredUsers(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UsersList));

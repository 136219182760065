import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import Badge from "vendor/components/Badge/Badge.jsx"
import Button from "vendor/components/CustomButtons/Button.jsx";

import AddGameForm from "./AddGameForm"
import EditGameForm from "./EditGameForm"

import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx";
import CustomModal from "UI/CustomModal/CustomModal"
import ConfirmModal from "UI/ConfirmModal/ConfirmModal"
import AddButton from "UI/CustomButtons/AddButton/AddButton"
import TooltipButton from 'UI/CustomButtons/TooltipButton/TooltipButton'
import OutlinedSelect from "UI/OutlinedSelect/OutlinedSelect";
import Pagination from "UI/Pagination/Pagination";
import ImageIcon from 'UI/ImageIcon/ImageIcon'
import CustomToggleButton from "UI/CustomToggleButton/CustomToggleButton.js";

import { getAllGames, deleteGame, clearGames } from 'Pages/Matches/actions/matches.js';
import { getAllLeagues, clearAllLeagues } from 'Pages/Leagues/actions/leagues.js';
import { getAllGameWeeksForLeague } from "Pages/GameWeeks/actions/gameWeeks.js";

import { connect } from 'react-redux';
import { darkBlueColor, getStatusObject, getMatchStatusColor } from 'utils/Constants'
import { IMAGE_TINY } from "../../../utils/Constants";

const styles = {

    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    badgeTitle: {
        marginRight: "10px"
    }
};


class GamesList extends React.Component {

    state = {
        addModalOpen: false,
        editModalOpen: false,
        confirmModalOpen: false,
        confirmModalChangeRound: false,
        confirmChanges: false,
        lastPage: 1,
        page: 1,
        game: {},
        league: {

        },
        week: {

        },
        editable: false
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.games !== this.props.games) {
            this.setState({
                page: nextProps.games.current_page,
                lastPage: nextProps.games.last_page,
            })
        }
    }

    componentWillMount() {
        this.props.clearAllLeagues();
        if (!(this.props.leagues !== undefined && this.props.leagues > 0)) {
            this.props.getAllLeagues(1);
        }
    }

    componentWillUnmount() {
        this.props.clearGames();
        this.props.clearAllLeagues();
    }

    editHandler = (id) => {
        var selected = this.props.games.data.find((t) => {
            return t.id === id;
        });
        this.setState({
            game: selected,
            editModalOpen: true,
            addModalOpen: false
        });
    }

    openConfirmDeleteHandler = (id) => {
        this.setState({
            confirmModalOpen: true,
            confirmMessage: "Are you sure you want to delete this game?",
            deleteId: id
        })
    }


    deleteHandler = (id) => {
        this.setState({ confirmModalOpen: false });
        this.props.deleteGame(id);
    }

    addHandler = (id) => {
        this.setState({ editModalOpen: false, addModalOpen: true });
    }

    closeAddModal = () => {
        this.setState({ addModalOpen: false });
    }

    closeEditModal = () => {
        this.setState({ editModalOpen: false });
    }

    closeConfirmModalHandler = (id) => {
        this.setState({ confirmModalOpen: false });
    }

    handleSelect = (id, name) => {
        var selected;
        if (name === "league") {
            selected = this.props.allLeagues.find((t) => {
                return t.id === id;
            });
            this.props.getAllGameWeeksForLeague(id);
        } else {
            selected = this.props.weeks.find((t) => {
                return t.id === id;
            });
        }

        this.setState({
            ...this.state,
            [name]: selected

        });
    }

    editableHandler = (event) => {
        this.setState({ editable: event.target.checked })
    }

    filterHandler = (page) => {
        let data = {
            ...this.state,
            page,
        };
        this.props.getAllGames(data);
    }

    clearFilters = () => {
        let data = {
            lastPage: 1,
            page: 1,
            league: {

            },
            week: {

            }
        };
        this.setState({ ...data });
    }

    render() {
        const { classes } = this.props;
        let mappedLeagues = [];
        const leagues = this.props.allLeagues
        if (leagues !== undefined && leagues.length > 0) {
            mappedLeagues = leagues.map(t => {
                return {
                    valueData: [
                        <div key={t.id} className={classes.selectItem}>
                            <ImageIcon big={IMAGE_TINY} image={t.image[0]} />
                            <span className={classes.badgeTitle}>{t.name}</span>
                        </div>
                    ],
                    id: t.id
                };
            });
        }

        let mappedGames = [];
        const games = this.props.games.data;
        if (games !== undefined && games.length > 0) {
            mappedGames = games.map(t => {
                const status = <Badge color={getMatchStatusColor(t)}>{t.match_status}</Badge>;
                const startTime = "" + new Date(t.match_date).toLocaleDateString("en-US") + ", " + t.match_time;
                const hostLogo = <ImageIcon image={{ url: t.home_team_badge }} />
                const guestLogo = <ImageIcon image={{ url: t.away_team_badge }} />
                const result = t.home_team_score + " : " + t.away_team_score;
                const edit = this.state.editable
                    ? <TooltipButton labelName="Edit" onClick={() => this.editHandler(t.id)}> <Edit /></TooltipButton>
                    : null; //todo visibility hidden
                const close = this.state.editable
                    ? <TooltipButton labelName="Delete" onClick={() => this.openConfirmDeleteHandler(t.id)}> <Close /></TooltipButton>
                    : null;
                return {
                    tableData: [startTime, t.home_team_name, hostLogo, result, guestLogo, t.away_team_name, status, t.match_shield, edit, close],
                    id: t.id
                };
            });
        }

        let mappedGameWeeks = [];
        const weeks = this.props.weeks;
        if (weeks !== undefined && weeks.length > 0) {
            mappedGameWeeks = weeks.map(t => {
                const status = getStatusObject(t);
                return {
                    valueData: [
                        <div key={t.id} className={classes.selectItem}>
                            <span className={classes.badgeTitle}>{"Week " + (t.number) + "  "}</span>
                            <Badge color={status.statusColor} >{status.statusText}</Badge>
                        </div>
                    ],
                    id: t.id
                }
            });
        }

        return (
            <div>
                <ConfirmModal
                    open={this.state.confirmModalOpen}
                    close={this.closeConfirmModalHandler}
                    message={this.state.confirmMessage}
                    yesButtonClisk={() => this.deleteHandler(this.state.deleteId)} />
                <CustomModal open={this.state.addModalOpen} close={this.closeAddModal}>
                    {<AddGameForm onClose={this.closeAddModal} />}
                </CustomModal>
                <CustomModal open={this.state.editModalOpen} close={this.closeEditModal}>

                    {<EditGameForm
                        league={this.state.league}
                        match={this.state.game}
                        onClose={this.closeEditModal}
                    />}
                </CustomModal>
                <CustomToggleButton toggleFilter={(pressed) => { this.setState({ editable: pressed }) }} />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>Matches per League:</h4>

                                {this.state.editable ? <AddButton labelName="Add Game" onClick={() => this.addHandler()} /> : null}
                            </CardHeader>
                            <CardBody>

                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedSelect
                                            labelName="League"
                                            selectData={mappedLeagues}
                                            value={this.state.league.id}
                                            onChange={(event) => this.handleSelect(event, "league")}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedSelect
                                            labelName="Game Week"
                                            selectData={mappedGameWeeks}
                                            value={this.state.week.id}
                                            onChange={(event) => this.handleSelect(event, "week")}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Button
                                            round
                                            onClick={() => this.filterHandler(1)}
                                            color="success">
                                            Filter
                                        </Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Button
                                            round
                                            onClick={this.clearFilters}
                                            color="danger">
                                            Clear Filters
                                        </Button>
                                    </GridItem>
                                </GridContainer>

                                <TableWithActionButtons
                                    tableHeaderColor="gray"
                                    tableHead={["Start Time", "Home", "   ", "  ", "   ", "Away", "Status", "Shield", "   ", "   "]}
                                    tableData={mappedGames}
                                />
                                <GridItem xs={12} sm={12} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "30px" }}>
                                    {this.state.lastPage > 1 ? <Pagination
                                        changePageTo={this.filterHandler}
                                        currentPage={this.state.page}
                                        totalPages={this.state.lastPage}
                                        color="warning"
                                    /> : null}
                                </GridItem>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        allLeagues: state.leagues.allLeagues,
        leagues: state.leagues.leaguesData,
        games: state.games.games,
        weeks: state.weeks.weeks
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllLeagues: (page) => dispatch(getAllLeagues(page, true)),
        getAllGames: (data) => dispatch(getAllGames(data)),
        deleteGame: (id) => dispatch(deleteGame(id)),
        getAllGameWeeksForLeague: (id) => dispatch(getAllGameWeeksForLeague(id)),
        clearGames: () => dispatch(clearGames()),
        clearAllLeagues: () => dispatch(clearAllLeagues())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GamesList));

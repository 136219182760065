import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import OutlinedSelect from "UI/OutlinedSelect/OutlinedSelect";
import CardFooter from "vendor/components/Card/CardFooter.jsx";
import CustomTextField from "vendor/components/CustomTextField/CustomTextField.jsx"
import CustomInput from "vendor/components/CustomInput/CustomInput.jsx";
import Close from "@material-ui/icons/Close";
import ImageIcon from 'UI/ImageIcon/ImageIcon'
import CustomPicker from "UI/CustomPicker/CustomPicker";
import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx";
import TooltipButton from 'UI/CustomButtons/TooltipButton/TooltipButton'
import UploadImage from "UI/UploadImage/UploadImage"
import CustomModal from "UI/CustomModal/CustomModal"
import { Scrollbars } from 'react-custom-scrollbars';
import { successColor } from "vendor/assets/jss/material-dashboard-react.jsx";
import { darkBlueColor } from "utils/Constants"
import { addSpecialRoom } from "Pages/SpecialRooms/actions/specialRooms";
import axios from "utils/axios";
import { showNotification } from "Toasts/actions/toasts";
import { connect } from 'react-redux';
import AddGamesForSpecialRoom from "./AddGamesForSpecialRoom";
import moment from "moment";
import AddRoundToSpecialRoom from "./AddRoundToSpecialRoom";

class AddSpecialRoom extends React.Component {

    state = {
        addRoundModalOpen: false,
        addModalOpen: false,
        confirmChanges: false,
        room: {
            name: null,
            registration_open_date: null,
            registration_close_date: null,
            max_players: null,
            prize_image: {
                id: null,
                url: null
            },
            prizes: [],
            entry_fee: null,
            start_date: null,
            end_date: null,
            rounds: []
        },
        prize_value: null,
        prize_position: null,
        addButtonDisabled: true,
        currentRound: null
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        if (prevProps.specialRooms && this.props.specialRooms && prevProps.specialRooms.length != this.props.specialRooms.length) {
            this.props.history.goBack();
        }
    }

    componentDidUpdate() {
        const addButtonDisabled = this.state.addButtonDisabled;
        if (addButtonDisabled != this.isAddButtonDisabled()) {
            this.setState({ addButtonDisabled: this.isAddButtonDisabled() })
        }
    }

    imageChangedHandler = (event) => {
        if (!event.target.files[0]) {
            return
        }
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (event) {
            var image = event.target.result;
            image = image.slice(image.indexOf(',') + 1);
            axios.post('/specialroom/image', { base64: image }).then((response) => {
                this.setState({
                    room: {
                        ...this.state.room,
                        prize_image: { ...response.data.data }
                    }
                });
            });
        }.bind(this);
    }

    closeAddModal = (data) => {
        if (!data || data === undefined) {
            this.setState({
                addModalOpen: false
            });
            return;
        }
        this.setState({
            addModalOpen: false,
            room: {
                ...this.state.room,
                rounds: this.state.room.rounds.map(t => {
                    if (t.number == this.state.currentRound.number) {
                        return {
                            ...t,
                            games: t.games.concat(data.ui_matches)
                        }
                    }
                    return { ...t }
                })
            }
        });

    }

    openAddModal = () => {
        if (!this.state.currentRound) {
            this.props.showNotification({
                type: "error",
                msg: "Create at least one round before adding games"
            });
            return;
        }
        this.setState({ addModalOpen: true });
    }

    handleInput = (name, value, isDate) => {
        if (isDate) {
            value = value.format("YYYY-MM-DD HH:mm")
        }
        this.setState({
            room: { ...this.state.room, [name]: value }
        })
    }

    deleteHandler = (id) => {
        this.setState({
            ...this.state,
            room: {
                ...this.state.room,
                rounds: this.state.room.rounds.map(t => {
                    if (t.number == this.state.currentRound.number) {
                        return {
                            ...t,
                            games: t.games.filter(u => u.id !== id)
                        }
                    }
                    return { ...t }
                })
            }
        })
    }

    deleteRound = () => {
        if (!this.state.currentRound) return;
        const rounds = this.state.room.rounds.filter(t => t.number !== this.state.currentRound.number);
        this.setState({
            ...this.state,
            room: {
                ...this.state.room,
                rounds
            },
            currentRound: rounds[0] ? rounds[0] : null
        })
    }

    addSpecialRoomHandler = () => {
        const data = {
            name: this.state.room.name,
            registration_open_date: this.state.room.registration_open_date,
            registration_close_date: this.state.room.registration_close_date,
            max_players: this.state.room.max_players,
            prize_image: this.state.room.prize_image.url === null ? null : this.state.room.prize_image,
            entry_fee: this.state.room.entry_fee,
            start_date: this.state.room.start_date,
            end_date: this.state.room.end_date,
            rounds: this.state.room.rounds.map(t => {
                return {
                    ...t,
                    games: t.games.map(u => {
                        return {
                            league_id: u.league_id,
                            match_id: u.id
                        }

                    })

                }
            }),
            prizes: this.state.room.prizes
        }
        console.log(data);
        this.props.addSpecialRoom(data);
    }

    isAddButtonDisabled = (matches) => {
        const room = this.state.room;
        const disabled = !(
            room.name && room.name !== ""
            && room.registration_open_date
            && room.registration_close_date
            && room.registration_open_date < room.registration_close_date
            && (
                (room.prize_image && room.prize_image.url)
                || (
                    room.prizes || room.prizes.length !== 0
                )
            )
            && room.entry_fee && room.entry_fee >= 0
            && room.start_date
            && room.end_date
            && room.start_date < room.end_date
            && room.rounds || room.rounds.length !== 0
        )

        return disabled;
    }

    addPrize = () => {
        if (this.state.room.prizes.findIndex(t => t.position == this.state.prize_position) !== -1 || !this.state.prize_position) {
            this.props.showNotification({
                type: "error",
                msg: "You cannot add the a prize for the same position twice!"
            });
            return;
        }
        this.setState({
            ...this.state,
            room: {
                ...this.state.room,
                prizes: this.state.room.prizes.concat({ position: this.state.prize_position, prize: this.state.prize_value })
            },
            prize_value: null,
            prize_position: null
        })
    }

    deletePrize = (position) => {
        this.setState({
            ...this.state,
            room: {
                ...this.state.room,
                prizes: this.state.room.prizes.filter(t => t.position !== position)
            }
        })
    }

    handleSelect = (value, name) => {
        this.setState({
            ...this.state,
            [name]: this.state.room.rounds.find(t => t.number === value)

        });
    }

    addRoundToState = (round) => {
        if (!round) {
            this.setState({ addRoundModalOpen: false })
            return
        }
        round.games = [];
        this.setState({
            ...this.state,
            addRoundModalOpen: false,
            room: {
                ...this.state.room,
                rounds: this.state.room.rounds.concat(round)
            },
            currentRound: round
        })
    }

    render() {
        const { classes } = this.props;
        const { currentRound } = this.state;
        let mappedGames = [];
        let games = [];
        if (currentRound) {
            const round = this.state.room.rounds.find(t => t.number === currentRound.number);
            if (round) {
                games = round.games;
            }
        }

        if (games !== undefined && games.length > 0) {
            mappedGames = games.map(t => {
                const startTime = "" + new Date(t.match_date).toLocaleDateString("en-US") + ", " + t.match_time;
                const deleteBtn = <TooltipButton labelName="Delete" onClick={() => this.deleteHandler(t.id)}> <Close /></TooltipButton>
                const hostLogo = <ImageIcon image={{ url: t.home_team_badge }} />
                const awayLogo = <ImageIcon image={{ url: t.away_team_badge }} />
                return {
                    tableData: [t.league_name, startTime, t.home_team_name, hostLogo, awayLogo, t.away_team_name, deleteBtn],
                    id: 1
                };
            });
        }

        let mappedPrizes = [];
        const { prizes } = this.state.room;
        if (prizes && prizes.length > 0) {
            mappedPrizes = prizes.map(t => {
                const deleteBtn = <TooltipButton labelName="Delete" onClick={() => this.deletePrize(t.position)}> <Close /></TooltipButton>
                return {
                    tableData: [t.position, t.prize, deleteBtn],
                    id: 1
                }
            });
        }

        let mappedRounds = [];
        const rounds = this.state.room.rounds;
        if (rounds && rounds.length > 0) {
            mappedRounds = rounds.map(t => {
                let dateFrom = moment(t.start_date).format("DD/MM/YYYY");
                let dateTo = moment(t.end_date).format("DD/MM/YYYY");
                return {
                    valueData: [
                        <div key={t.number} className={classes.selectItem}>
                            <span className={classes.badgeTitle}>Round {t.number} from {dateFrom} to {dateTo}</span>
                        </div>
                    ],
                    id: t.number
                };
            });
        }
        let roundStartDate = null;
        if (this.state.room.rounds[this.state.room.rounds.length - 1]) {
            roundStartDate = this.state.room.rounds[this.state.room.rounds.length - 1].end_date
        } else if (this.state.room.start_date) {
            let roomStart = new Date(this.state.room.start_date);
            roomStart.setDate(roomStart.getDate());
            roundStartDate = roomStart;
        }


        return (
            <React.Fragment>
                <CustomModal open={this.state.addRoundModalOpen} close={this.addRoundToState}>
                    {<AddRoundToSpecialRoom onClose={this.addRoundToState}
                        currentRounds={this.state.room.rounds}
                        minDate={roundStartDate}
                        maxDate={this.state.room.end_date} />}
                </CustomModal>
                <CustomModal open={this.state.addModalOpen && this.state.currentRound} close={this.closeAddModal}>
                    {<AddGamesForSpecialRoom
                        onClose={this.closeAddModal}
                        startDate={this.state.currentRound && this.state.currentRound.start_date}
                        endDate={this.state.currentRound && this.state.currentRound.end_date} />}
                </CustomModal>
                <GridContainer className={classes.container}>
                    <Card className={classes.card}>
                        <CardHeader color="warning" className={classes.cardHeader}>
                            <h4 className={classes.cardTitle}>Add special game</h4>
                        </CardHeader>
                        <Scrollbars style={{ height: "60vh" }}>
                            <CardBody>
                                <GridContainer style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <UploadImage
                                            description="Upload prize image"
                                            image={this.state.room.prize_image}
                                            onChange={this.imageChangedHandler}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} className={classes.grid} style={{ marginTop: "40px" }}>
                                        <div>Add prizes: </div>
                                    </GridItem>
                                    <GridItem xs={6} sm={3} md={3} className={classes.grid}>
                                        <CustomInput
                                            labelText="Position"
                                            id="prize_position"
                                            inputProps={{
                                                value: this.state.prize_position,
                                                onChange: (event) => {

                                                    this.setState({ prize_position: event.target.value })
                                                }
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={6} sm={3} md={3} className={classes.grid}>
                                        <CustomInput
                                            labelText="Prize ammount or kind"
                                            id="prize_value"
                                            inputProps={{
                                                value: this.state.prize_value,
                                                onChange: (event) => this.setState({ prize_value: event.target.value })
                                            }}
                                        />

                                    </GridItem>
                                    <GridItem xs={6} sm={3} md={3} className={classes.grid}>
                                        <Button
                                            onClick={this.addPrize}
                                            color="success">
                                            Add prize
                                        </Button>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} md={6} className={classes.grid} style={{ marginBottom: "40px" }}>
                                        <TableWithActionButtons
                                            style={{ marginBottom: "40px" }}
                                            tableHeaderColor="gray"
                                            tableHead={["Position", "Prize", "   "]}
                                            tableData={mappedPrizes}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} className={classes.grid}>
                                        <CustomInput
                                            labelText="Name"
                                            id="name"
                                            fullWidth={true}
                                            inputProps={{
                                                value: this.state.room.name,
                                                onChange: (event) => this.handleInput("name", event.target.value)
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomTextField
                                            inputType="number"
                                            labelText="Max players"
                                            id={"max_players"}
                                            fullWidth={true}
                                            inputProps={{
                                                value: this.state.room.max_players,
                                                onChange: (event) => this.handleInput("max_players", event.target.value)
                                            }}

                                        />
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                        <CustomPicker
                                            labelName="Registration open time"
                                            onChange={(date) => this.handleInput("registration_open_date", date, true)}
                                            date={this.state.room.registration_open_date}
                                            fullWidth={true}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                        <CustomPicker
                                            labelName="Start date"
                                            onChange={(date) => this.handleInput("start_date", date, true)}
                                            date={this.state.room.start_date}

                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                        <CustomPicker
                                            labelName="Registration close time"
                                            onChange={(date) => this.handleInput("registration_close_date", date, true)}
                                            date={this.state.room.registration_close_date}
                                            fullWidth={true}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                        <CustomPicker
                                            labelName="End date"
                                            onChange={(date) => this.handleInput("end_date", date, true)}
                                            date={this.state.room.end_date}

                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            isNumber={true}
                                            labelText="Entry fee"
                                            id={"entry_fee"}
                                            fullWidth={true}
                                            inputProps={{
                                                value: this.state.room.entry_fee,
                                                onChange: (event) => this.handleInput("entry_fee", event.target.value)
                                            }}

                                        />
                                    </GridItem>


                                    <GridItem xs={9} sm={9} md={9} className={classes.grid} style={{ marginTop: "40px" }}>
                                        <OutlinedSelect
                                            labelName="Current round"
                                            selectData={mappedRounds}
                                            value={this.state.currentRound && this.state.currentRound.number}
                                            onChange={(event) => this.handleSelect(event, "currentRound")}
                                        />

                                        <Button
                                            style={{ marginLeft: "50px" }}
                                            onClick={this.deleteRound}
                                            color="danger">
                                            Delete current Round
                                        </Button>
                                        <Button
                                            style={{ marginLeft: "50px" }}
                                            onClick={this.openAddModal}
                                            color="success">
                                            Add games for current round
                                        </Button>
                                    </GridItem>
                                    <GridItem xs={9} sm={9} md={9} className={classes.grid} style={{ marginTop: "40px" }}>
                                        <Button
                                            style={{ marginLeft: "50px" }}
                                            onClick={() => {
                                                if (this.state.room.start_date && this.state.room.end_date) {
                                                    this.setState({ addRoundModalOpen: true })
                                                } else {
                                                    this.props.showNotification({
                                                        type: "error",
                                                        msg: "Define start and end dates for this special game first"
                                                    });
                                                }
                                            }}
                                            color="success">
                                            Add new Round
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <TableWithActionButtons
                                        tableHeaderColor="gray"
                                        tableHead={["League", "Start Time", "Home", "   ", "   ", "Away", "   "]}
                                        tableData={mappedGames}
                                    />
                                </GridContainer>
                            </CardBody>
                        </Scrollbars>
                        <CardFooter className={classes.cardFooter}>
                            <Button
                                disabled={this.state.addButtonDisabled}
                                onClick={this.addSpecialRoomHandler}
                                color="success">
                                Add special Game
                            </Button>
                        </CardFooter>
                    </Card>
                </GridContainer>
            </React.Fragment>
        );
    };

}

const styles = theme => ({
    grid: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none"
    },
    card: {
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "0",
        flexBasis: "100%"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    cardHeader: {
        width: "100%",
        zIndex: "10"
    },
    cardFooter: {
        zIndex: "10",
        background: "white",
        borderTop: "none",
        [theme.breakpoints.down("sm")]: {
            borderTop: "1px solid " + successColor
        },
    },
    selectItem: {
        display: "flex",
        alignItems: "center",
    },
    badgeTitle: {
        marginRight: "10px"
    }
});

const mapStateToProps = state => {
    return {
        specialRooms: state.specialRooms.specialRooms
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addSpecialRoom: (data) => dispatch(addSpecialRoom(data)),
        showNotification: (data) => dispatch(showNotification(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddSpecialRoom));

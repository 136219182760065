import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import List from "@material-ui/icons/List";
// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";

import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx";
import TooltipButton from 'UI/CustomButtons/TooltipButton/TooltipButton'
import Pagination from "UI/Pagination/Pagination";

import { connect } from 'react-redux';
import { darkBlueColor } from 'utils/Constants'
import { getRoomInfo } from "Pages/GameRooms/actions/gameRooms"

const styles = {

    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    badgeTitle: {
        marginRight: "10px"
    },
    inputIconsColor: {
        color: "lightgray"
    },
};

class GameRoomInfo extends React.Component {

    state = {
        lastPage: 1,
        page: 1
    }

    componentWillMount() {
        this.filterHandler(1);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.room !== this.props.room) {
            this.setState({
                page: nextProps.room.current_page,
                lastPage: nextProps.room.last_page,
            })
        }
    }

    filterHandler = (page) => {
        this.props.getRoomInfo({ leagueId: this.props.match.params.league_id, page: page, roomId: this.props.match.params.id });
    }

    infoHandler = (userId) => {
        this.props.history.push(
            "/userInRoomInfo/" + userId
            + "/" + this.props.match.params.id
            + "/" + this.props.match.params.league_id
            + "/" + this.props.room.data.find(t => t.id === userId).name
        );
    }

    render() {
        const { classes } = this.props;

        let mappedRoom = [];
        if (this.props.room && this.props.room.data && this.props.room.data.length > 0) {
            mappedRoom = this.props.room.data.map(t => {
                const info = <TooltipButton labelName="Info" onClick={() => this.infoHandler(t.id)}> <List /></TooltipButton>
                return {
                    tableData: [t.name, t.email, t.total_points, info],
                    id: t.id
                };
            });
        }

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>{this.props.match.params.room_name}</h4>
                            </CardHeader>
                            <CardBody>
                                <TableWithActionButtons
                                    tableHeaderColor="gray"
                                    tableHead={["Name", "Email", "Total points", "   "]}
                                    tableData={mappedRoom}

                                />
                            </CardBody>
                            <GridItem xs={12} sm={12} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {this.state.lastPage > 1 ? <Pagination
                                    changePageTo={this.filterHandler}
                                    currentPage={this.state.page}
                                    totalPages={this.state.lastPage}
                                    color="warning"
                                /> : null}
                            </GridItem>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );

    }
}

const mapStateToProps = state => {

    return {
        room: state.rooms.room
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getRoomInfo: (data) => dispatch(getRoomInfo(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GameRoomInfo));

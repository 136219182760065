import React, { Fragment, PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DateTimePicker, DatePicker } from 'material-ui-pickers';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import amber from '@material-ui/core/colors/amber';

const materialTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: amber['600'],
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                // backgroundColor: amber.A200,
                // color: 'white',
            },
        },
        MuiPickersDay: {
            day: {
                color: amber.A900,
                '&$selected': {
                    backgroundColor: amber['800'],
                    '&:hover': {
                        backgroundColor: amber['300'],
                    }
                },
            },
            current: {
                color: amber['900'],
                backgroundColor: amber['800'],
            },

        },

        MuiPickersClock: {

            pin: {
                backgroundColor: amber['800']
            }
        },
        MuiPickersClockPointer: {

            pointer: {
                backgroundColor: amber['800']
            },
            thumb: {
                borderColor: amber['800']
            },
            noPoint: {
                backgroundColor: amber['800']
            }
        },

        MuiIconButton: {
            "&:hover": {
                backgroundColor: amber['300'],
            }
        },
        MuiPickersModal: {
            dialogAction: {
                color: amber['800'],
            },
        },
    },
});

const styles = theme => ({
    picker: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'flex-start',
        height: "100%"
    }
});

class BasicDateTimePicker extends PureComponent {


    handleDateChange = date => {
        this.props.onChange(date)
    };

    render() {

        const { classes } = this.props;

        return (
            <Fragment>
                <MuiThemeProvider theme={materialTheme}>
                    <div className={classes.picker}>
                        {this.props.dateOnly ? 
                            <DatePicker
                            showTabs={false}
                            autoOk
                            ampm={false}
                            value={this.props.date}
                            onChange={this.handleDateChange}
                            label={this.props.labelName}
                            showTodayButton
                            minDate={this.props.minDate}
                            maxDate={this.props.maxDate}
                        />
                        :
                        <DateTimePicker
                            showTabs={false}
                            autoOk
                            ampm={false}
                            value={this.props.date}
                            onChange={this.handleDateChange}
                            label={this.props.labelName}
                            showTodayButton
                            minDate={this.props.minDate}
                            maxDate={this.props.maxDate}
                        />}
                    </div>
                </MuiThemeProvider>
            </Fragment>
        );
    }
}

export default withStyles(styles)(BasicDateTimePicker);
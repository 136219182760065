import { GET_ALL_ROOMS, GET_ROOM_INFO, GET_USER_IN_ROOM_INFO, CLEAR_ROOMS } from '../actions/actionTypes';

const initialState = {
    rooms: [],
    room: {},
    user: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_ROOMS:
            return {
                ...state,
                rooms: {...action.rooms}
            }
        case GET_ROOM_INFO: 
        return {
            ...state,
            room: action.room
        }

        case GET_USER_IN_ROOM_INFO: 
        return {
            ...state,
            user: action.user
        }

        case CLEAR_ROOMS: {
            return {
                ...state,
                rooms: [],
                room: {},
                user: {}
            }
        }
        default:
            return state;
    }
}
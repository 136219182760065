import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import CustomTextField from "vendor/components/CustomTextField/CustomTextField.jsx"
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CardFooter from "vendor/components/Card/CardFooter.jsx";
import UploadDocumentImage from './UploadDocumentImage';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


import { darkBlueColor } from "utils/Constants"
import { uploadFile, addAdvert, deleteUploadImage } from "Pages/Advertisements/actions/advertisements"

import { connect } from 'react-redux';


class AddAdvertismentsForm extends React.Component {

    state = {
        link: null,
        league_id: 1,
        start_date: null,
        end_date: null,
        required_documents: {},
        adImage: null,
        time: null,
        title: null,
        status: false,
    }

    changeStatus = () => { this.setState({ status: !this.state.status }) };

    linkChangeHandler = (event) => {
        this.setState({ link: event.target.value });
    }

    timeChangeHandler = (event) => {
        this.setState({ time: event.target.value });
    }

    titleChangeHandler = (event) => {
        this.setState({ title: event.target.value });
    }

    startDateChangedHandler = (date) => {
        const start_date = date.format("YYYY-MM-DD HH:mm");
        this.setState({ start_date: start_date });
    }

    endDateChangedHandler = (date) => {
        const end_date = date.format("YYYY-MM-DD HH:mm");
        this.setState({ end_date: end_date });
    }

    addRoundHandler = () => {
        const { uploadFileObject } = this.props;
        const { title, link, time, status } = this.state;

        let data;
        if (uploadFileObject.isVideo) {
            data = {
                position_id: this.props.advertId,
                is_active: status,
                title: title,
                link_url: link,
                show_after: time,
                image: null,
                video: uploadFileObject,
            }
        } else {
            data = {
                position_id: this.props.advertId,
                is_active: status,
                title: title,
                link_url: link,
                show_after: time,
                image: uploadFileObject,
                video: null,
            }
        }



        this.props.addAdvert(data)
        this.props.deleteUploadImage();
        this.props.onClose()
    }

    addDocumentImage = (event, context, property) => {

        if (!event.target.files) {
            return
        }

        var files = event.target.files;
        if (files && files[0]) {
            this.props.uploadFile(files[0]);
        }

        for (var index = 0; index < files.length; index++) {
            var reader = new FileReader();
            reader.readAsDataURL(files[index]);
            reader.onload = function (event) {
                var image = event.target.result;
                if (image.includes("data:video/mp4")) {
                    this.setState({ adImage: null, adVideo: image })
                } else {
                    this.setState({ adImage: image, adVideo: null });
                }
                image = image.slice(image.indexOf(',') + 1);

            }.bind(this);



        }
    }



    render() {
        const { classes, title } = this.props;
        const { adImage, adVideo } = this.state;
        return (
            <div>
                <GridContainer className={classes.container}>
                    <Card className={classes.card}>
                        <CardHeader color="warning" className={classes.cardHeader}>
                            <h4 className={classes.cardTitle}>Add {title}</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CustomTextField
                                        inputType="text"
                                        labelText="Link"
                                        id="link"
                                        inputProps={{
                                            value: this.state.link,
                                            onChange: (event) => this.linkChangeHandler(event)
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CustomTextField
                                        inputType="number"
                                        labelText="Time in seconds"
                                        id="time"
                                        inputProps={{
                                            value: this.state.time,
                                            onChange: (event) => this.timeChangeHandler(event)
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CustomTextField
                                        inputType="title"
                                        labelText="Title"
                                        id="title"
                                        inputProps={{
                                            value: this.state.title,
                                            onChange: (event) => this.titleChangeHandler(event)
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={this.state.status}
                                        onChange={this.changeStatus}
                                        color="primary"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                        name="status" />}
                                    label="Status"
                                    style={{ paddingLeft: "1rem" }}
                                />
                                <GridItem xs={12} sm={12} md={12}>
                                    <UploadDocumentImage
                                        image={adImage}
                                        video={adVideo}
                                        description={"Advertisement"}
                                        onChange={(event) => this.addDocumentImage(event, "required_documents", "id_card_front")}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button
                                onClick={this.addRoundHandler}
                                color="success">
                                Add
                            </Button>
                        </CardFooter>
                    </Card>
                </GridContainer>
            </div>
        );
    };

}

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none"
    },
    card: {
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "0",
        flexBasis: "100%"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        marginTop: "-5rem"
    },
    cardHeader: {
        width: "100%",
    },
    selectItem: {
        display: "flex",
        alignItems: "center",
    }
};
const mapStateToProps = state => {
    return {
        uploadFileObject: state.advertisement.uploadFile
    }
}
const mapDispatchToProps = dispatch => {
    return {
        uploadFile: data => dispatch(uploadFile(data)),
        addAdvert: data => dispatch(addAdvert(data)),
        deleteUploadImage: data => dispatch(deleteUploadImage())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddAdvertismentsForm));

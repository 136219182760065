import {
    GET_ALL_GAMES,
    DELETE_GAME,
    GET_ALL_TEAMS_FOR_LEAGUE,
    GET_GAME,
    EDIT_GAME,
    ADD_GAME,
    CLEAR_ALL_GAMES
} from 'Pages/Matches/actions/actionTypes';
import { CLEAR_GAMES } from '../actions/actionTypes';

const initialState = {
    games: [],
    teams: [],
    allGames: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_GAMES: {
            return {
                ...state,
                games: action.games,
                allGames: state.allGames.concat(action.games.data)
            }
        }
        case CLEAR_ALL_GAMES: {
            return {
                ...state,
                allGames: []
            }
        }
        case DELETE_GAME: {
            return {
                ...state,
                games: {
                    ...state.games,
                    data: state.games.data.filter(g => g.id !== action.id)
                }
            }
        }

        case GET_ALL_TEAMS_FOR_LEAGUE: {
            return {
                ...state,
                teams: action.teams
            }
        }

        case GET_GAME: {
            return {
                ...state,
                game: action.game
            }
        }

        case EDIT_GAME: {
            const mappedGame = mapGame(action.game);
            return {
                ...state,
                games: {
                    data: state.games.data.map((it) => {
                        if (it.id === action.game.id) {
                            return {
                                ...mappedGame
                            };
                        }
                        return it;
                    })
                }
            }
        }

        case ADD_GAME: {
            const mappedGame = mapGame(action.game)
            return {
                ...state,
                games: state.games.data.concat(mappedGame)
            }
        }

        case CLEAR_GAMES: {
            return {
                ...state,
                games: [],
                teams: []
            }
        }

        default:
            return state;
    }
}

const mapGame = (game) => {
    return {
        id: game.id,
        match_api_id: game.match_api_id,
        league_id: game.league_id,
        home_team_name: game.match_hometeam_name,
        home_team_id: game.home_team_id,
        home_team_badge: game.hometeam_badge,
        home_team_score: game.match_hometeam_score,
        away_team_score: game.match_awayteam_score,
        away_team_id: game.away_team_id,
        away_team_name: game.match_awayteam_name,
        away_team_badge: game.awayteam_badge,
        match_status: game.match_status,
        match_live: game.match_live,
        match_time: game.match_time,
        match_date: game.match_date,
        match_game_week: game.game_week,
        match_shield: game.match_shield,
        calculated_at: game.calculated_at,
        created_at: game.created_at
    }
}
import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getAllGames = (data, shouldAppend) => {
    return dispatch => {
        axios.get("/admin/matches/" + data.league.id, { params: { game_round_id: data.week.id, page: data.page } })
            .then(res => {
                if (shouldAppend && res.data.data.last_page > 1) {
                    for (var i = 2; i <= res.data.data.last_page; i++) {
                        axios.get("/admin/matches/" + data.league.id, { params: { game_round_id: data.week.id, page: i } })
                            .then(res => {
                                dispatch(setAllGamesForLeague(res.data.data));
                            })
                    }
                }
                dispatch(setAllGamesForLeague(res.data.data));
            });
    };
}

export const clearAllGames = () => {
    return dispatch => dispatch({ type: actionTypes.CLEAR_ALL_GAMES })
}

export const setAllGamesForLeague = (games) => {
    return {
        type: actionTypes.GET_ALL_GAMES,
        games
    }
}

export const deleteGame = (id) => {
    return dispatch => {
        axios.delete("match/" + id)
            .then(res => {
                dispatch(setGameDeleted(id));
            })
    }
}

export const setGameDeleted = (id) => {
    return {
        type: actionTypes.DELETE_GAME,
        id
    }
}

export const getAllTeamsForChampionship = (id) => {
    return dispatch => {
        axios.get("/teams", { params: { league_id: id } })
            .then(res => {
                dispatch(setAllTeamsForChampionship(res.data.data));
            });
    }
}

export const setAllTeamsForChampionship = (teams) => {
    return {
        type: actionTypes.GET_ALL_TEAMS_FOR_LEAGUE,
        teams
    }
}

export const getMatchById = (id) => {
    return dispatch => {
        axios.get("/match/" + id)
            .then(res => {
                dispatch(setMatch(res.data.data));
            });
    }
}

export const setMatch = (game) => {
    return {
        type: actionTypes.GET_GAME,
        game: game.data[0]
    }
}

export const editGame = (data) => {
    return dispatch => {
        axios.put("/match", data)
            .then(res => {
                if (res.data.has_error === false) {
                    dispatch(setEditGame(res.data.data));
                }
            });
    }
}

export const setEditGame = (game) => {
    return {
        type: actionTypes.EDIT_GAME,
        game
    }
}

export const addGame = (data) => {
    return dispatch => {
        axios.post('/match', data)
            .then(res => {
                if (res.data.has_error === false) {
                    dispatch(setAddedGame(res.data.data));
                }
            });
    };
};

export const setAddedGame = (game) => {
    return {
        type: actionTypes.ADD_GAME,
        game
    };
};

export const clearGames = () => {
    return {
        type: actionTypes.CLEAR_GAMES
    }
}

import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import AddButton from "UI/CustomButtons/AddButton/AddButton"
import Edit from "@material-ui/icons/Edit";
import TooltipButton from 'UI/CustomButtons/TooltipButton/TooltipButton'
import { getAllSpecialRooms } from 'Pages/SpecialRooms/actions/specialRooms.js';
import { connect } from 'react-redux';
import { darkBlueColor, convertDate } from 'utils/Constants'
import List from "@material-ui/icons/List";

const styles = {

    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    badgeTitle: {
        marginRight: "10px"
    },
    inputIconsColor: {
        color: "lightgray"
    },
};

const UPCOMING = "UPCOMING";
const RUNNING = "RUNNING";
const OVER = "OVER";

class SpecialRoomsList extends React.Component {

    state = {
        filter: RUNNING
    }

    componentWillMount() { //TODO did mount
        if (!(this.props.specialRooms && this.props.specialRooms.length > 0)) {
            this.props.getAllSpecialRooms();
        }
    }

    addHandler = () => {
        this.props.history.push("/addSpecialRoom");
    }

    editHandler = (id) => {
        this.props.history.push("/editSpecialRoom/" + id);
    }

    previewHandler = (id) => {
        this.props.history.push("/previewSpecialRoom/" + id);
    }

    getMappedRooms = () => {
        let mappedRooms = [];
        if (this.props.specialRooms && this.props.specialRooms.length > 0) {
            mappedRooms = this.props.specialRooms.filter(t => {
                switch(this.state.filter) {
                    case UPCOMING: {
                        return new Date(t.start_date) > new Date()
                    }
                    case RUNNING: {
                        return moment().isBetween(moment(t.start_date), moment(t.end_date))                    }
                    case OVER: {
                        return new Date(t.end_date) < new Date() 
                    }
                    default: {
                        return new Date(t.start_date) >= new Date() && new Date(t.end_date) <= new Date()
                    }
                }
            }).map(t => {
                const regOpenDate = convertDate(t.registration_close_date);
                const regCloseDate = convertDate(t.registration_close_date);
                const startDate = convertDate(t.start_date);
                const endDate = convertDate(t.end_date);
                const edit = <TooltipButton labelName="Edit" onClick={() => this.editHandler(t.id)}> <Edit /></TooltipButton>
                const preview = <TooltipButton labelName="Preview" onClick={() => this.previewHandler(t.id)}> <List /></TooltipButton>
                return {
                    tableData: [t.name, regOpenDate, regCloseDate, t.max_players, t.entry_fee, startDate, endDate, preview, edit],
                    id: t.id
                };
            });
        }
        return mappedRooms;
    }

    render() {
        const { classes } = this.props;

        let mappedRooms = this.getMappedRooms();

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="warning" className={classes.card}>
                            <h4 className={classes.cardTitle}>Special games</h4>
                            <Button color={"success"} onClick={() => this.setState({filter: UPCOMING})}>Upcoming</Button>
                            <Button color={"success"}onClick={() => this.setState({filter: RUNNING})}>Running</Button>
                            <Button color={"success"}onClick={() => this.setState({filter: OVER})}>Over</Button>
                            <AddButton labelName="Add Special Game" onClick={() => this.addHandler()} />
                        </CardHeader>
                        
                        <CardBody>
                            <TableWithActionButtons
                                tableHeaderColor="gray"
                                tableHead={["Name", "Reg Open Date", "Reg Close Date", "Max Players", "Entry fee", "Start Date", "End Date", "   ", "   "]}
                                tableData={mappedRooms}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
        specialRooms: state.specialRooms.specialRooms
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllSpecialRooms: () => dispatch(getAllSpecialRooms())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SpecialRoomsList));

import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import Badge from "vendor/components/Badge/Badge.jsx"
import Button from "vendor/components/CustomButtons/Button.jsx";

import AddGameWeekForm from "./AddGameWeekForm"
import EditGameWeekForm from "./EditGameWeekForm"

import Table from "UI/Table/Table.jsx"
import CustomModal from "UI/CustomModal/CustomModal"
import ConfirmModal from "UI/ConfirmModal/ConfirmModal"
import AddButton from "UI/CustomButtons/AddButton/AddButton"
import OutlinedSelect from "UI/OutlinedSelect/OutlinedSelect";
import Pagination from "UI/Pagination/Pagination";
import ImageIcon from 'UI/ImageIcon/ImageIcon'

import { getAllLeagues, clearAllLeagues } from 'Pages/Leagues/actions/leagues.js';
import { getAllGameWeeksForLeague, deleteGameWeek, clearGameWeeks } from "Pages/GameWeeks/actions/gameWeeks.js";
import { connect } from 'react-redux';
import { darkBlueColor, getStatusObject } from 'utils/Constants'
import { IMAGE_TINY } from "../../../utils/Constants";

const styles = {
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    selectItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    badgeTitle: {
        marginLeft: "10px"
    }
};

class GameWeeksList extends React.Component {

    state = {
        addModalOpen: false,
        editModalOpen: false,
        confirmModalOpen: false,
        confirmModalChangeRound: false,
        confirmChanges: false,
        leagueId: null 
    }

    componentWillMount() {
        this.props.clearAllLeagues();
        if (!(this.props.leagues !== undefined && this.props.leagues > 0)) {
            this.props.getAllLeagues(1);
        }
    }

    componentWillUnmount() {
        this.props.clearGameWeeks();
        this.props.clearAllLeagues();
    }

    editHandler = (id) => {
        var selected = this.props.weeks.find((t) => {
            return t.id === id;
        });

        this.setState({
            addModalOpen: false,
            editModalOpen: true,
            week: selected
        });
    }

    openConfirmDeleteHandler = (id) => {
        this.setState({
            confirmModalOpen: true,
            confirmMessage: "Are you sure you want to delete this item?",
            deleteId: id
        })
    }

    deleteHandler = (id) => {
        this.setState({ confirmModalOpen: false });
        this.props.deleteGameWeek(id);
    }

    addHandler = (id) => {
        this.setState({ editModalOpen: false, addModalOpen: true });
    }

    closeAddModal = () => {
        this.setState({ addModalOpen: false });
    }

    closeEditModal = () => {
        this.setState({ editModalOpen: false });
    }

    closeConfirmModalHandler = (id) => {
        this.setState({ confirmModalOpen: false, selectedGameWeekId: id });
    }

    handleSelect = (id) => {
        this.setState({
            ...this.state,
            leagueId: id
        });
    }

    filterHandler = () => {
        this.props.getAllGameWeeksForLeague(this.state.leagueId);
    }

    render() {
        const { classes } = this.props;

        let mappedLeagues = [];
        const leagues = this.props.allLeagues
        if (leagues !== undefined && leagues.length > 0) {
            mappedLeagues = leagues.map(t => {
                return {
                    valueData: [
                        <div key={t.id} className={classes.selectItem}>
                            <ImageIcon big={IMAGE_TINY} image={t.image[0]} />
                            <span className={classes.badgeTitle}>{t.name}</span>
                        </div>
                    ],
                    id: t.id
                };
            });
        }

        let mappedGameWeeks = [];
        const weeks = this.props.weeks;
        if (weeks !== undefined && weeks.length > 0) {
            mappedGameWeeks = weeks.map(t => {
                const statusojb = getStatusObject(t);
                const status = <Badge color={statusojb.statusColor}>{statusojb.statusText}</Badge>;
                const startTime = new Date(t.start_date).toLocaleDateString("en-US");
                const endTime = new Date(t.end_date).toLocaleDateString("en-US");
                return {
                    tableData: [t.number, startTime, endTime, status],
                    id: t.id
                };
            });
        }

        return (
            <div>
                 <ConfirmModal
                    open={this.state.confirmModalOpen}
                    close={this.closeConfirmModalHandler}
                    message={this.state.confirmMessage}
                    yesButtonClisk={() => this.deleteHandler(this.state.deleteId)} />
                <CustomModal open={this.state.addModalOpen} close={this.closeAddModal}>
                    <AddGameWeekForm onClose={this.closeAddModal} leagueId={this.state.leagueId} />
                </CustomModal>
                <CustomModal open={this.state.editModalOpen} close={this.closeEditModal}>
                   <EditGameWeekForm
                        round={this.state.week}
                        onClose={this.closeEditModal}
                        gameWeek={this.state.week}
                    />
                </CustomModal>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>Game weeks per league:</h4>
                                <AddButton labelName="Add Game Week" onClick={() => this.addHandler()} />
                            </CardHeader>
                            <CardBody>
                                <GridContainer style={{alignItems: "center"}}>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedSelect
                                            labelName="League"
                                            selectData={mappedLeagues}
                                            value={this.state.leagueId}
                                            onChange={(event) => this.handleSelect(event)}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={3}>
                                        <Button
                                            round
                                            onClick={this.filterHandler}
                                            color="success">
                                            Filter
                                        </Button>
                                    </GridItem>
                                </GridContainer>

                                <Table
                                    tableHeaderColor="gray"
                                    tableHead={["Number", "Start Date", "End Date", "Status", "   "]}
                                    tableData={mappedGameWeeks}
                                    editHandler={this.editHandler}
                                    deleteHandler={this.openConfirmDeleteHandler}
                                />
                                <GridItem xs={12} sm={12} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    {this.state.lastPage > 1 ? <Pagination
                                        changePageTo={this.onChangePage}
                                        currentPage={this.state.page}
                                        totalPages={this.state.lastPage}
                                        color="warning"
                                    /> : null}
                                    </GridItem>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        leagues: state.leagues.leagues,
        weeks: state.weeks.weeks,
        allLeagues: state.leagues.allLeagues
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllLeagues: (page) => dispatch(getAllLeagues(page, true)),
        getAllGameWeeksForLeague: (id) => dispatch(getAllGameWeeksForLeague(id)),
        deleteGameWeek: (id) => dispatch(deleteGameWeek(id)),
        clearGameWeeks: () => dispatch(clearGameWeeks()),
        clearAllLeagues: () => dispatch(clearAllLeagues())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GameWeeksList));

/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "UI/Header/Header.jsx";
import Footer from "vendor/components/Footer/Footer.jsx";
import Sidebar from "UI/SideBar/SideBar.js";
import { withRouter } from 'react-router-dom';

import adminRoutes from "routes/appRoutes.jsx";
import dashboardStyle from "vendor/assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import AddSpecialRoom from "../Pages/SpecialRooms/screens/AddSpecialRoom";
import EditSpecialRoom from "../Pages/SpecialRooms/screens/EditSpecialRoom";
import GameRoomInfo from "Pages/GameRooms/screens/GameRoomInfo";
import UserInRoomInfo from "Pages/GameRooms/screens/UserInRoomInfo";
import UserInfoList from "../Pages/Users/screens/UserInfoList";
import UserPredictionsList from "../Pages/Users/screens/UserPredictionsList";
import PreviewSpecialRoom from "Pages/SpecialRooms/screens/PreviewSpecialRoom";
import ListUserInSpecialRoomPredictions from "../Pages/SpecialRooms/screens/ListUserInSpecialRoomPredictions";

class MainPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false
        };
        this.resizeFunction = this.resizeFunction.bind(this);
    }

    componentWillMount() {
        const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
        if (!loggedUser) {
            this.props.history.index = 0;
            this.props.history.replace('/login');
        }
    }

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    };
    getRoute() {
        return this.props.location.pathname !== "/login";
    }
    resizeFunction() {
        if (window.innerWidth >= 960) {
            this.setState({ mobileOpen: false });
        }
    }
    componentDidMount() {
        window.addEventListener("resize", this.resizeFunction);
    }
    componentDidUpdate(e) {
        if (e.history.location.pathname !== e.location.pathname) {
            this.refs.mainPanel.scrollTop = 0;
            if (this.state.mobileOpen) {
                this.setState({ mobileOpen: false });
            }
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeFunction);
    }
    render() {
        const { classes, ...rest } = this.props;
        const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
        return (
            <div className={classes.wrapper}>
                <Sidebar
                    routes={adminRoutes}
                    logoText={"Glowter Admin"}
                    handleDrawerToggle={this.handleDrawerToggle}
                    open={this.state.mobileOpen}
                    color="orange"
                    {...rest}
                />

                <div className={classes.mainPanel} ref="mainPanel">
                    <Header
                        routes={adminRoutes}
                        handleDrawerToggle={this.handleDrawerToggle}
                        {...rest}
                    />


                    <div className={classes.content}>
                        <div className={classes.container}>{
                            <Switch>
                                <Route path="/addSpecialRoom/" component={AddSpecialRoom} />
                                <Route path="/editSpecialRoom/:id" component={EditSpecialRoom} />
                                <Route path="/roomInfo/:id/:league_id/:room_name" component={GameRoomInfo} />
                                <Route path="/userInRoomInfo/:user_id/:room_id/:league_id/:user_name" component={UserInRoomInfo} />
                                <Route path="/userInfo/:id/:user_name" component={UserInfoList} />
                                <Route path="/predictions/:id/:user_name" component={UserPredictionsList} />
                                <Route path="/previewSpecialRoom/:id" component={PreviewSpecialRoom} />
                                <Route path="/editPredictionForSpecialRoom/:id" component={ListUserInSpecialRoomPredictions} />
                                {adminRoutes.map((prop, key) => {
                                    if (prop.redirect && loggedUser)
                                        return <Redirect from={prop.path} to={prop.to} key={key} />;
                                    return <Route path={prop.path} component={prop.component} key={key} />;
                                })}
                            </Switch>
                        }</div>
                    </div>

                    <Footer />
                </div>
            </div>
        );
    }
}

MainPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(dashboardStyle)(MainPage));

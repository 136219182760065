import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CardFooter from "vendor/components/Card/CardFooter.jsx";
import CustomSelect from "UI/CustomSelect/CustomSelect";
import CustomPicker from "UI/CustomPicker/CustomPicker";
import UploadImage from "UI/UploadImage/UploadImage"
import { Scrollbars } from 'react-custom-scrollbars';
import { successColor } from "vendor/assets/jss/material-dashboard-react.jsx";

import { darkBlueColor } from "utils/Constants"
import { addLeague, getAllValidLeagues } from "Pages/Leagues/actions/leagues"
import axios from "utils/axios";

import { connect } from 'react-redux';

const START_DATE = "start_date";
const END_DATE = "end_date";

class AddLeagueForm extends React.Component {

    state = {
        league_api_id: null,
        image: null,
        name: null,
        start_date: null,
        end_date: null,
        addButtonDisabled: true
    }

    componentDidMount() {
        this.props.getAllValidLeagues();
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        if (prevProps.leagues && this.props.leagues && prevProps.leagues.length != this.props.leagues.length) {
            this.props.history.goBack();
        }
    }

    componentDidUpdate() {
        const addButtonDisabled = this.state.addButtonDisabled;
        if (addButtonDisabled != this.isAddButtonDisabled()) {
            this.setState({ addButtonDisabled: this.isAddButtonDisabled() })
        }
    }

    isAddButtonDisabled = () => {
        const state = this.state;
        return !(state.image && state.image.url
            && state.name && state.name != ""
            && state.start_date && state.end_date
            && state.start_date <= state.end_date
            && state.league_api_id)
    }

    dateChangedHandler = (date, propName) => {
        const formattedDate = date.format("YYYY-MM-DD HH:mm");
        this.setState({ [propName]: formattedDate });
    }

    addHandler = () => {
        this.props.addLeague(this.state)
        this.props.onClose()
    }

    imageChangedHandler = (event) => {
        if (!event.target.files[0]) {
            return
        }
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (event) {
            var image = event.target.result;
            image = image.slice(image.indexOf(',') + 1);
            axios.post('/images', { base64: image }).then((response) => {
                this.setState({ image: response.data.data });
            });
        }.bind(this);
    }

    handleLeagueSelect = (id) => {
        this.setState({ league_api_id: id, name: this.props.apiLeagues.find(t => t.league_api_id === id).league_name });
    }


    render() {
        const { classes } = this.props;

        let mappedLeagues = [];
        if (this.props.apiLeagues.length > 0) {
            const leagues = this.props.apiLeagues.sort((a, b) => {
                if (a.country_name > b.country_name) return 1;
                if (a.country_name < b.country_name) return -1;
                return 0;
            }
            );
            mappedLeagues = leagues.map(t => {
                return {
                    valueData: [
                        <div key={t.league_api_id} className={classes.selectItem}>
                            {t.country_name + " - " + t.league_name}
                        </div>
                    ],
                    id: t.league_api_id
                };
            });
        }

        return (
            <div>

                <GridContainer className={classes.container}>
                    <Card className={classes.card}>

                        <CardHeader color="warning" className={classes.cardHeader}>
                            <h4 className={classes.cardTitle}>Add league</h4>
                        </CardHeader>
                        <Scrollbars style={{ height: "60vh" }}>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <UploadImage
                                            description="Upload league image"
                                            image={this.state.image}
                                            onChange={this.imageChangedHandler}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} className={classes.grid}>
                                        <CustomSelect
                                            labelName="League"
                                            selectData={mappedLeagues}
                                            value={this.state.league_api_id}
                                            onChange={(event) => this.handleLeagueSelect(event)}
                                        />
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                        <CustomPicker
                                            labelName="Start date"
                                            onChange={(event) => this.dateChangedHandler(event, START_DATE)}
                                            date={this.state.start_date}

                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                        <CustomPicker
                                            labelName="End date"
                                            onChange={(event) => this.dateChangedHandler(event, END_DATE)}
                                            date={this.state.end_date}

                                        />
                                    </GridItem>
                                </GridContainer>

                            </CardBody>
                        </Scrollbars>
                        <CardFooter className={classes.cardFooter}>
                            <Button
                                disabled={this.state.addButtonDisabled}
                                onClick={this.addHandler}
                                color="success">
                                Add League
                            </Button>
                        </CardFooter>

                    </Card>
                </GridContainer>
            </div>
        );
    };

}

const styles = theme => ({
    grid: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginTop: "10px",
        marginBottom: "10px"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none"
    },
    card: {
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "0",
        flexBasis: "100%"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    cardHeader: {
        width: "100%",
        zIndex: "10"
    },
    cardFooter: {
        zIndex: "10",
        background: "white",
        borderTop: "none",
        [theme.breakpoints.down("sm")]: {
            borderTop: "1px solid " + successColor
        },
    },
    selectItem: {
        display: "flex",
        alignItems: "center",
    },
    badgeTitle: {
        marginRight: "10px"
    }
});

const mapStateToProps = state => {
    return {
        apiLeagues: state.leagues.apiLeagues,
        leagues: state.leagues.leagues
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllValidLeagues: () => dispatch(getAllValidLeagues()),
        addLeague: (data) => dispatch(addLeague(data))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddLeagueForm));

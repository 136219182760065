import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import ImageIcon from 'UI/ImageIcon/ImageIcon';
import { getUserRooms } from 'Pages/Users/actions/users.js';
import { connect } from 'react-redux';
import { darkBlueColor } from 'utils/Constants'

import Pagination from "UI/Pagination/Pagination";

const styles = {

    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    badgeTitle: {
        marginRight: "10px"
    },
    inputIconsColor: {
        color: "lightgray"
    },
};

class UserInfoList extends React.Component {

    state = {
        lastPage: 1,
        page: 1,
        rooms: []
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.rooms !== this.props.rooms) {
            this.setState({
                page: nextProps.rooms.current_page,
                lastPage: nextProps.rooms.last_page,
                rooms: this.mapRooms(nextProps.rooms.data)
            })
        }
    }

    mapRooms = (props) => {
        const rooms = [];
        props.leagues.forEach(league => {
            league.rooms.forEach(room => {
                rooms.push({
                    ...room,
                    image: league.image,
                    league_name: league.name
                })
            });
        });
        return rooms;
    }

    componentDidMount() {
        this.getUserInfo(1);
    }

    getUserInfo(page) {
        this.props.getUserRooms(this.props.match.params.id, page);
    }

    render() {
        const { classes } = this.props;

        let mappedRooms = [];
        if (this.state.rooms && this.state.rooms.length > 0) {
            mappedRooms = this.state.rooms.map(t => {
                const avatar = <ImageIcon image={t.image} />;
                const isActive = t.is_active ? "Active" : "Not Active"
                const joinedDate = new Date(t.joined).toLocaleDateString("en-US")
                return {
                    tableData: [avatar, t.league_name, t.name, isActive, joinedDate, t.position, t.total_points],
                    id: t.id
                };
            });
        }
       
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>Rooms for User: {this.props.match.params.user_name} </h4>
                            </CardHeader>
                            <CardBody>
                                <TableWithActionButtons
                                    tableHeaderColor="gray"
                                    tableHead={["   ", "League", "Room", "Active", "Joined At", "Position", "Total Points"]}
                                    tableData={mappedRooms}

                                /> 
                            </CardBody>
                            <GridItem xs={12} sm={12} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {this.state.lastPage > 1 ? <Pagination
                                    changePageTo={this.getUserInfo}
                                    currentPage={this.state.page}
                                    totalPages={this.state.lastPage}
                                    color="warning"
                                /> : null}
                            </GridItem>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );

    }
}

const mapStateToProps = state => {
    return {
        rooms: state.users.rooms
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getUserRooms: (id, page) => dispatch(getUserRooms(id, page))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserInfoList));

import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from 'react-redux';

// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import CustomInput from "vendor/components/CustomInput/CustomInput.jsx";

import Button from "vendor/components/CustomButtons/Button.jsx";
import CustomPicker from "UI/CustomPicker/CustomPicker";
import { darkBlueColor, getStatusObject } from 'utils/Constants'
import { showNotification } from "Toasts/actions/toasts";

const styles = {

    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    badgeTitle: {
        marginRight: "10px"
    }
};


class AddGamesForSpecialRoom extends React.Component {

    state = {

        start_date: null,
        end_date: null,
        number: null
    }

    addHandler = () => {
        if (this.state.start_date && this.state.end_date && this.state.number) {
            this.props.currentRounds.forEach(t => {
                if (t.number == this.state.number) {
                    this.props.showNotification({
                        type: "error",
                        msg: "This round number already exists"
                    });
                    this.props.onClose(null)
                    return;
                }
            })
            this.props.onClose({ ...this.state });
        } else {
            this.props.showNotification({
                type: "error",
                msg: "All fields are required"
            });
        }
    }

    render() {

        const { classes } = this.props;
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>Add a new round</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput

                                            labelText="Round"
                                            id="number"
                                            inputProps={{
                                                type: "number",
                                                value: this.state.number,
                                                onChange: (event) => {
                                                    this.setState({ number: +event.target.value })
                                                }
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomPicker
                                            labelName="Start Date"
                                            onChange={(date) => {
                                                this.setState({
                                                    start_date: date.format("YYYY-MM-DD")
                                                })
                                            }}
                                            minDate={this.props.minDate}
                                            maxDate={this.props.maxDate}
                                            dateOnly={true}
                                            date={this.state.start_date}
                                            fullWidth={true}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} />
                                    <GridItem xs={12} sm={6} md={6}>
                                        <CustomPicker
                                            labelName="End Date"
                                            onChange={(date) => {
                                                this.setState({
                                                    end_date: date.format("YYYY-MM-DD")
                                                })
                                            }}
                                            minDate={this.state.start_date ? this.state.start_date : this.props.minDate}
                                            maxDate={this.props.maxDate}
                                            dateOnly={true}
                                            date={this.state.end_date}
                                            fullWidth={true}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Button
                                            round
                                            onClick={this.addHandler}
                                            color="success">
                                            Add round
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>

                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showNotification: (data) => dispatch(showNotification(data)),
    }
}


export default connect(null, mapDispatchToProps)(withStyles(styles)(AddGamesForSpecialRoom));

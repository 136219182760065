import { GET_GAME_WEEKS, DELETE_GAME_WEEK, ADD_GAME_WEEK, EDIT_GAME_WEEK, CLEAR_GAME_WEEKS } from '../actions/actionTypes';

const initialState = {
    weeks: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_GAME_WEEKS: {
            const weeks = [...action.weeks.game_rounds];
            return {
                ...state,
                weeks
                // weeks: weeks.map( (t) => {
                //     return {
                //         ...t,
                //         number: t.game_round
                //     }
                // })
            }
        }

        case ADD_GAME_WEEK: {
            return {
                ...state,
                weeks: state.weeks.concat(action.newWeek)
            }
        }

        case EDIT_GAME_WEEK: {
            return {
                ...state,
                weeks: state.weeks.map((it, index) => {
                    if (it.id === action.week.id) {
                        return { ...action.week };
                    }
                    return it;
                })
            }
        }

        case DELETE_GAME_WEEK: {
            return {
                ...state,
                weeks: state.weeks.filter(it => it.id !== action.id)
            }
        }

        case CLEAR_GAME_WEEKS: {
            return {
                ...state,
                weeks: []
            }
        }

        default:
            return state;
    }
}
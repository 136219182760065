import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ShuffleIcon from '@material-ui/icons/Shuffle';
// core components
import GridItem from "vendor/components/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import Card from "vendor/components/Card/Card.jsx";
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CustomTextField from "vendor/components/CustomTextField/CustomTextField.jsx"
import CustomInput from "vendor/components/CustomInput/CustomInput.jsx";
import ImageIcon from 'UI/ImageIcon/ImageIcon'
import moment from "moment";
import OutlinedSelect from "UI/OutlinedSelect/OutlinedSelect";
import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx";
import TooltipButton from 'UI/CustomButtons/TooltipButton/TooltipButton'
import { Scrollbars } from 'react-custom-scrollbars';
import { successColor } from "vendor/assets/jss/material-dashboard-react.jsx";
import { darkBlueColor } from "utils/Constants"
import { getSpecialRoom } from "Pages/SpecialRooms/actions/specialRooms";

import { connect } from 'react-redux';
import { IMAGE_LARGE } from "../../../utils/Constants";

class PreviewSpecialRoom extends React.Component {

    state = {
        addModalOpen: false,
        confirmChanges: false,
        room: {
            name: null,
            registration_open_date: null,
            registration_close_date: null,
            max_players: null,
            prize_image: {
                id: null,
                url: null
            },
            entry_fee: null,
            start_date: null,
            end_date: null,
            rounds: []
        },
        currentRound: null
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.specialRoom !== this.props.specialRoom) {
            const response = nextProps.specialRoom;
            this.setState({
                ...this.state,
                room: {
                    id: response.id,
                    name: response.name,
                    registration_open_date: response.registration_open_date,
                    registration_close_date: response.registration_close_date,
                    max_players: response.max_players,
                    prize_image: { ...response.prize_image },
                    entry_fee: response.entry_fee,
                    start_date: response.start_date,
                    end_date: response.end_date,
                    rounds: response.rounds,
                    prizes: response.prizes
                },
                prize_value: null,
                prize_position: null
            })
        }
    }

    componentWillMount() {
        this.props.getSpecialRoom(this.props.match.params.id);
    }

    predictionsHandler = (id) => {
        this.props.history.push("/editPredictionForSpecialRoom/" + id)
    }

    handleSelect = (value, name) => {
        this.setState({
            ...this.state,
            [name]: this.state.room.rounds.find(t => t.number === value)

        });
    }

    render() {
        const { classes } = this.props;
        const { currentRound } = this.state;

        let mappedParticipants = [];
        const participants = this.props.specialRoom.participants;
        if (participants !== undefined && participants.length > 0) {
            mappedParticipants = participants.map(t => {
                const userLogo = <ImageIcon image={t.image} />
                const predictionsBtn = <TooltipButton labelName="Predictions" onClick={() => this.predictionsHandler(t.user_id)}> <ShuffleIcon /></TooltipButton>
                return {
                    tableData: [userLogo, t.first_name, t.email, t.away_team_name, predictionsBtn],
                    id: t.user_id
                };
            });
        }

        let mappedGames = [];
        let games = [];
        if (currentRound) {
            const round = this.state.room.rounds.find(t => t.number === currentRound.number);
            if (round) {
                games = round.games;
            }
        }
        if (games !== undefined && games.length > 0) {
            mappedGames = games.map(t => {
                const startTime = "" + new Date(t.match_date).toLocaleDateString("en-US") + ", " + t.match_time;
                const hostLogo = <ImageIcon image={{ url: t.home_team_badge }} />
                const awayLogo = <ImageIcon image={{ url: t.away_team_badge }} />
                return {
                    tableData: [t.league_name, startTime, t.home_team_name, hostLogo, awayLogo, t.away_team_name],
                    id: t.id
                };
            });
        }

        let mappedRounds = [];
        const rounds = this.state.room.rounds;
        if (rounds && rounds.length > 0) {
            mappedRounds = rounds.map(t => {
                let dateFrom = moment(t.start_date).format("DD/MM/YYYY");
                let dateTo = moment(t.end_date).format("DD/MM/YYYY");
                return {
                    valueData: [
                        <div key={t.number} className={classes.selectItem}>
                            <span className={classes.badgeTitle}>Round {t.number} from {dateFrom} to {dateTo}</span>
                        </div>
                    ],
                    id: t.number
                };
            });
        }

        let mappedPrizes = [];
        const { prizes } = this.state.room;
        if (prizes && prizes.length > 0) {
            mappedPrizes = prizes.map(t => {
                return {
                    tableData: [t.position, t.prize],
                    id: 1
                }
            });
        }

        return (
            <React.Fragment>
                <GridContainer className={classes.container}>
                    <Card className={classes.card}>
                        <CardHeader color="warning" className={classes.cardHeader}>
                            <h4 className={classes.cardTitle}>Preview special room</h4>
                        </CardHeader>
                        <Scrollbars style={{ height: "60vh" }}>
                            <CardBody>
                                <GridContainer style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <ImageIcon
                                            big={IMAGE_LARGE}
                                            image={this.state.room.prize_image}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={8} className={classes.grid} style={{marginBottom: "40px"}}>
                                        <TableWithActionButtons
                                            style={{ marginBottom: "40px" }}
                                            tableHeaderColor="gray"
                                            tableHead={["Position", "Prize"]}
                                            tableData={mappedPrizes}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                        <CustomInput
                                            labelText="Name"
                                            id="name"
                                            inputProps={{
                                                readOnly: true,
                                                value: this.state.room.name
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Max players"
                                            id="max_players"
                                            fullWidth={true}
                                            inputProps={{
                                                readOnly: true,
                                                value: this.state.room.max_players
                                            }}

                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                        <CustomInput
                                            labelText="Registration open time"
                                            inputProps={{
                                                readOnly: true,
                                                value: this.state.room.registration_open_date
                                            }}
                                            fullWidth={true}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                        <CustomInput
                                            labelText="Registration close time"
                                            inputProps={{
                                                readOnly: true,
                                                value: this.state.room.registration_close_date
                                            }}
                                            fullWidth={true}
                                        />
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Entry fee"
                                            id={"entry_fee"}
                                            fullWidth={true}
                                            inputProps={{
                                                readOnly: true,
                                                value: this.state.room.entry_fee
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                        <CustomInput
                                            labelText="Start date"
                                            inputProps={{
                                                readOnly: true,
                                                value: this.state.room.start_date
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                        <CustomInput
                                            labelText="End date"
                                            inputProps={{
                                                readOnly: true,
                                                value: this.state.room.end_date
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>

                                <GridContainer style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", marginTop: "30px" }}>
                                    <GridItem xs={12} sm={12} md={12} className={classes.grid} style={{ marginTop: "40px" }}>
                                        <OutlinedSelect
                                            labelName="Current round"
                                            selectData={mappedRounds}
                                            value={this.state.currentRound && this.state.currentRound.number}
                                            onChange={(event) => this.handleSelect(event, "currentRound")} />
                                    </GridItem>
                                    <h2>Games</h2>
                                    <TableWithActionButtons
                                        tableHeaderColor="gray"
                                        tableHead={["League", "Start Time", "Home", "   ", "   ", "Away"]}
                                        tableData={mappedGames}
                                    />
                                </GridContainer>
                                <GridContainer style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", marginTop: "30px" }}>
                                    <h2>Participants</h2>
                                    <TableWithActionButtons
                                        tableHeaderColor="gray"
                                        tableHead={["    ", "First Name", "Email", "   ", "    "]}
                                        tableData={mappedParticipants}
                                    />
                                </GridContainer>
                            </CardBody>
                        </Scrollbars>
                    </Card>
                </GridContainer>
            </React.Fragment>
        );
    };

}

const styles = theme => ({
    grid: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none"
    },
    card: {
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "0",
        flexBasis: "100%"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    cardHeader: {
        width: "100%",
        zIndex: "10"
    },
    cardFooter: {
        zIndex: "10",
        background: "white",
        borderTop: "none",
        [theme.breakpoints.down("sm")]: {
            borderTop: "1px solid " + successColor
        },
    },
    selectItem: {
        display: "flex",
        alignItems: "center",
    },
    badgeTitle: {
        marginRight: "10px"
    }
});

const mapStateToProps = (state) => {
    return {
        specialRoom: state.specialRooms.specialRoom,
        special_edited: state.specialRooms.special_edited,
        game: state.games.game
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSpecialRoom: (id) => dispatch(getSpecialRoom(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PreviewSpecialRoom));
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { darkYellowColor } from "utils/Constants"
import InputAdornment from "@material-ui/core/InputAdornment";

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: "1em",
        marginBottom: "2em",
        // width: "100%"

    },
    textField: {

        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        height: "3em",
        marginTop: "8px",
        marginLeft: "0px",
        marginBottom: "4px",
        // backgroundColor: "white",
        // borderRadius: "40px",


    },
    label: {
        fontSize: "1.1em",
        transform: "translate(14px, 8px) scale(1)",
        '&$cssFocused $notchedOutline': {
            //color: `${ "red"} !important`
        }

    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: `${darkYellowColor} !important`
        }
    },

    cssFocused: {
        color: `${darkYellowColor} !important`
    },

    notchedOutline: {
        borderWidth: '1px',
        // borderRadius: "40px",
    }
});

class OutlinedInput extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <form className={classes.container}>

                <TextField
                    disabled={this.props.disabled}
                    className={classes.textField}
                    style={{ height: this.props.height }}
                    name={this.props.name}
                    type={this.props.type}
                    label={this.props.labelName}
                    id={this.props.id}
                    autoComplete={this.props.autoComplete}
                    fullWidth={this.props.fullWidth}
                    multiline={this.props.multiline}
                    rowsMax={this.props.rowsMax}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        inputProps: { min: 0 },
                        classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline
                        },
                        style: { height: this.props.height },
                        fullWidth: this.props.fullWidth,
                        value: this.props.value,
                        onChange: this.props.onChange,
                        endAdornment: this.props.icon ? <InputAdornment position="end">{this.props.icon}</InputAdornment> : null,
                    }}
                    InputLabelProps={{

                        className: classes.label,
                        classes: {
                            focused: classes.cssFocused
                        }
                    }} />
            </form>
        );
    }
}

OutlinedInput.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(OutlinedInput);
import {
    GET_ALL_SPECIAL_ROOMS,
    GET_SPECIAL_ROOM,
    EDIT_SPECIAL_ROOM,
    CLEAR_FOR_ADD_SPECIAL_ROOM,
    ADD_SPECIAL_ROOM,
    EDIT_SPECIAL_ROOM_PREDICTION
} from '../actions/actionTypes';

const initialState = {
    specialRooms: [],
    specialRoom: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_SPECIAL_ROOMS: {
            return {
                ...state,
                specialRooms: [...action.specialRooms]
            }
        }

        case CLEAR_FOR_ADD_SPECIAL_ROOM: {
            return {
                ...state,
                special_added: null,
                special_edited: null
            }
        }

        case GET_SPECIAL_ROOM: {
            return {
                ...state,
                specialRoom: { ...action.specialRoom },
                special_added: null,
                special_edited: null
            }
        }

        case EDIT_SPECIAL_ROOM: {
            return {
                ...state,
                specialRooms: state.specialRooms.map(specialRoom => {
                    if (specialRoom.id === action.special_edited.id) {
                        return {
                            ...action.special_edited
                        }
                    }
                    return {...specialRoom}
                })
            }
        }

        case ADD_SPECIAL_ROOM: {
            return {
                ...state,
                specialRooms: state.specialRooms.concat(action.special_added)
            }
        }

        case EDIT_SPECIAL_ROOM_PREDICTION: {
            return {
                ...state,
                specialRoom: {
                    ...state.specialRoom,
                    participants: state.specialRoom.participants.map(participant => {
                        if (participant.user_id == action.editedSpecialPrediction.user_id) {
                            return {
                                ...participant,
                                predictions: participant.predictions.map(prediction => {
                                    if (prediction.game_id == action.editedSpecialPrediction.game_id) {
                                        return {
                                            ...prediction,
                                            prediction: action.editedSpecialPrediction.prediction
                                        }
                                    }
                                    return {...prediction}
                                })    
                            }
                        }
                        return {...participant}
                    })
                }
            }
        }
        default:
            return state;
    }
}
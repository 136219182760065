import { UPLOAD_FILE, GET_ALL_ADVERTS, DELETE_ADVERT, GET_ALL_ACTIONS, DELETE_UPLOAD_IMAGE } from '../actions/actionTypes';

const initialState = {
    uploadFile: null,
    adverts: [],
    actions: [],


}

export default (state = initialState, action) => {
    switch (action.type) {
        case DELETE_UPLOAD_IMAGE: {
            let uploadFile = { ...state.uploadFile };
            uploadFile = null;
            return {
                ...state,
                uploadFile: uploadFile
            }
        }
        case UPLOAD_FILE: {
            let uploadFile = { ...state.uploadFile };
            uploadFile = action.uploadFile;
            return {
                ...state,
                uploadFile: uploadFile
            }
        }
        case GET_ALL_ADVERTS: {
            return {
                ...state,
                adverts: action.adverts
            }
        }
        case GET_ALL_ACTIONS: {
            return {
                ...state,
                actions: action.actions
            }
        }


        case DELETE_ADVERT: {
            const adverts = [...state.adverts];
            const advertsIndex = adverts.findIndex(ad => ad.position_id === action.positionId);
            const advertisements = adverts[advertsIndex].advertisements.filter(ad => ad.id !== action.id);
            adverts[advertsIndex].advertisements = advertisements;
            return {
                ...state,
                adverts: adverts
            }
        }
        default:
            return state;
    }
}
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import Badge from "vendor/components/Badge/Badge.jsx"
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CardFooter from "vendor/components/Card/CardFooter.jsx";
import CustomSelect from "UI/CustomSelect/CustomSelect";
import CustomTextField from "vendor/components/CustomTextField/CustomTextField.jsx"
import CustomPicker from "UI/CustomPicker/CustomPicker";
import ImageIcon from "UI/ImageIcon/ImageIcon"
import { Scrollbars } from 'react-custom-scrollbars';
import { successColor } from "vendor/assets/jss/material-dashboard-react.jsx";
import { darkBlueColor, getStatusObject } from "utils/Constants"
import { getAllTeamsForChampionship, editGame, getMatchById } from 'Pages/Matches/actions/matches.js';
import moment from "moment";

import { connect } from 'react-redux';

const HOST = "home_team_id";
const GUEST = "away_team_id";
const SHIELD = "match_shield"
const HOME_SCORE = "home_team_score";
const AWAY_SCORE = "away_team_score";
const HOME_FILTERED_TEAMS = "homeTeams";
const AWAY_FILTERED_TEAMS = "awayTeams";
const GAME_WEEK = "match_game_week"

class EditGameForm extends React.Component {

    state = {
        league: {},
        game: {

        },
        homeTeams: [],
        awayTeams: []
    }

    componentWillMount() {
        this.setState({
            league: this.props.league,
            game: { ...this.props.match },
            start_date: "" + new Date(this.props.match.match_date).toLocaleDateString("en-US") + ", " + this.props.match.match_time
        });
        this.props.getAllTeamsForChampionship(this.props.league.id);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.teams !== this.props.teams) {
            this.setState({
                ...this.state,
                homeTeams: [...nextProps.teams].filter(t => t.team_id !== this.props.match.away_team_id),
                awayTeams: [...nextProps.teams].filter(t => t.team_id !== this.props.match.home_team_id)
            });
        }
    }

    handleSelect = (id, name) => {
        var selected;
        switch (name) {
            case GAME_WEEK: {
                selected = this.props.weeks.find((t) => {
                    return t.game_round === id;
                });
                this.setState({
                    game: {
                        ...this.state.game,
                        [name]: selected.game_round
                    }
                });
                break;
            }

            case GUEST: case HOST: {
                selected = this.props.teams.find((t) => {
                    return t.team_id === id;
                }).team_id;
                let selectedArray;
                let otherArray;
                if (name === GUEST) {
                    selectedArray = HOME_FILTERED_TEAMS;
                    otherArray = AWAY_FILTERED_TEAMS;
                } else {
                    selectedArray = AWAY_FILTERED_TEAMS;
                    otherArray = HOME_FILTERED_TEAMS;
                }

                this.setState({
                    ...this.state,
                    game: {
                        ...this.state.game,
                        [name]: selected
                    },
                    [selectedArray]: this.state[selectedArray].filter(t => t.team_id !== selected),
                    [otherArray]: [...this.props.teams]
                });
                break;
            }

            case SHIELD: case AWAY_SCORE: case HOME_SCORE: {
                selected = id;
                this.setState({
                    ...this.state,
                    game: {
                        ...this.state.game,
                        [name]: selected
                    }
                });
                break;
            }

            default: {
                // Empty
            }
        }

    }

    dateChangedHandler = (date) => {
        const start_date = date.format("YYYY-MM-DD HH:mm");
        this.setState({ start_date: start_date });
    }

    editGameHandler = () => {
        this.props.onClose()
        const match_date = moment(new Date(this.state.start_date)).format("YYYY-MM-DD");
        const options = {
            hour12: false,
            hour: "2-digit",
            minute: "2-digit"
        }
        const match_time = new Date(this.state.start_date).toLocaleTimeString("en-US", options);

        const data = {
            id: this.state.game.id,
            home_team_id: this.state.game.home_team_id,
            home_team_score: this.state.game.home_team_score,
            away_team_id: this.state.game.away_team_id,
            away_team_score: this.state.game.away_team_score,
            match_time: match_time,
            match_date: match_date,
            match_shield: this.state.game.match_shield,
            game_week: this.state.game.match_game_week
        }

        this.nullifyEmptyValues([SHIELD, HOME_SCORE, AWAY_SCORE, "game_week"], data);
        this.props.editGame(data);
    }

    nullifyEmptyValues = (propertyNames, data) => {
        propertyNames.forEach(propertyName => {
            if (data[propertyName] === undefined
                || ((propertyName !== HOME_SCORE && propertyName !== AWAY_SCORE) && data[propertyName] === 0)
                || data[propertyName] === "") {
                data[propertyName] = null;
            }
        });
    }

    mapTeams = (teamsArrayName, classes) => {
        if (this.props.teams.length > 0) {
            return this.state[teamsArrayName].map(t => {
                return {
                    valueData: [
                        <div key={t.team_id} className={classes.selectItem}>
                            <ImageIcon image={{ url: t.team_badge }} />
                            {t.team_name}
                        </div>
                    ],
                    id: t.team_id
                };
            });
        } else return [];
    }

    render() {
        const { classes } = this.props;

        let mappedHomeTeams = this.mapTeams(HOME_FILTERED_TEAMS, classes);
        let mappedAwayTeams = this.mapTeams(AWAY_FILTERED_TEAMS, classes);

        let mappedWeeks = [];
        if (this.props.weeks.length > 0) {
            mappedWeeks = this.props.weeks.map(t => {
                const status = getStatusObject(t);
                return {
                    valueData: [
                        <div key={t.number} className={classes.selectItem}>
                            <span className={classes.badgeTitle}>{"Week " + t.number + " "}</span>
                            <Badge color={status.statusColor}>{status.statusText}</Badge>
                        </div>
                    ],
                    id: t.number
                };
            });
        }
        return (
            <GridContainer className={classes.container}>
                <Card className={classes.card}>
                    <CardHeader color="warning" className={classes.cardHeader}>
                        <h4 className={classes.cardTitle}>Edit game</h4>
                    </CardHeader>
                    <Scrollbars style={{ height: "60vh" }}>
                        <CardBody>
                            <GridContainer style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <CustomSelect
                                        labelName="Week"
                                        selectData={mappedWeeks}
                                        value={this.state.game.match_game_week}
                                        fullWidth={true}
                                        onChange={(event) => this.handleSelect(event, GAME_WEEK)}

                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <CustomPicker
                                        labelName="Start time"
                                        onChange={this.dateChangedHandler}
                                        date={this.state.start_date}
                                        fullWidth={true}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <CustomSelect
                                        labelName="Home"
                                        selectData={mappedHomeTeams}
                                        value={this.state.game.home_team_id}
                                        fullWidth={true}
                                        onChange={(event) => this.handleSelect(event, HOST)}

                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomTextField
                                        inputType="number"
                                        labelText="Home team score"
                                        id={HOME_SCORE}
                                        fullWidth={true}
                                        inputProps={{
                                            value: this.state.game.home_team_score,
                                            onChange: (event) => this.handleSelect(event.target.value, HOME_SCORE)
                                        }}

                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <CustomSelect
                                        labelName="Away"
                                        selectData={mappedAwayTeams}
                                        onChange={(event) => this.handleSelect(event, GUEST)}
                                        fullWidth={true}
                                        value={this.state.game.away_team_id}

                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomTextField
                                        inputType="number"
                                        labelText="Away team score"
                                        id={HOME_SCORE}
                                        fullWidth={true}
                                        inputProps={{
                                            value: this.state.game.away_team_score,
                                            onChange: (event) => this.handleSelect(event.target.value, AWAY_SCORE)
                                        }}

                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomTextField
                                        inputType="number"
                                        labelText="Shield"
                                        id={SHIELD}
                                        fullWidth={true}
                                        inputProps={{
                                            value: this.state.game.shield,
                                            onChange: (event) => this.handleSelect(event.target.value, SHIELD)
                                        }}

                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Scrollbars>
                    <CardFooter className={classes.cardFooter}>
                        <Button
                            onClick={this.editGameHandler}
                            color="success">
                            Edit game
                            </Button>
                    </CardFooter>
                </Card>
            </GridContainer>
        );
    };
}

const styles = theme => ({
    grid: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none"
    },
    card: {
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "0",
        flexBasis: "100%"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    cardHeader: {
        width: "100%",
        zIndex: "10"
    },
    cardFooter: {
        zIndex: "10",
        background: "white",
        borderTop: "none",
        [theme.breakpoints.down("sm")]: {
            borderTop: "1px solid " + successColor
        },
    },
    selectItem: {
        display: "flex",
        alignItems: "center",
    },
    badgeTitle: {
        marginRight: "10px"
    }
});

const mapStateToProps = state => {
    return {
        teams: state.games.teams,
        weeks: state.weeks.weeks,
        game: state.games.game
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllTeamsForChampionship: id => dispatch(getAllTeamsForChampionship(id)),
        editGame: data => dispatch(editGame(data)),
        getMatchById: (id) => dispatch(getMatchById(id))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditGameForm));

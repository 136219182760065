import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getUsersEmails = (data) => {
    return dispatch => {
        axios.post('/notifications/emails', data)
            .then(res => {
                dispatch(setUsersEmails(res.data.data));
            });
    };
};

export const setUsersEmails = (data) => {
    return {
        type: actionTypes.GET_USERS_EMAILS,
        data: data
    };
};

export const sendGlobalNotification = (data) => {
    return dispatch => {
        axios.post('/notifications/global', data);
    };
};

export const sendUserNotification = (data) => {
    return dispatch => {
        axios.post('/notifications/user', data);
    };
};
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Edit from "@material-ui/icons/Edit";
// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import TooltipButton from 'UI/CustomButtons/TooltipButton/TooltipButton';
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import Pagination from "UI/Pagination/Pagination";
import Badge from "vendor/components/Badge/Badge.jsx"
import CustomModal from "UI/CustomModal/CustomModal"
import AddButton from "UI/CustomButtons/AddButton/AddButton"

import { getAllLeagues, deleteLeague } from 'Pages/Leagues/actions/leagues.js';
import { connect } from 'react-redux';
import { darkBlueColor, getStatusObject } from 'utils/Constants'
import AddLeagueForm from "./AddLeagueForm";
import EditLeagueForm from "./EditLeagueForm";

const styles = {
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }
};

class LeaguesList extends React.Component {

    state = {
        addModalOpen: false,
        editModalOpen: false,
        confirmModalOpen: false,
        confirmModalChangeRound: false,
        confirmChanges: false,
        page: 1,
        lastPage: 1
    }

    componentWillMount() {
        if (!(this.props.leagues !== undefined && this.props.leagues > 0)) {
            this.props.getAllLeagues(1);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.leaguesData !== this.props.leaguesData) {
            this.setState({
                page: nextProps.leaguesData.current_page,
                lastPage: nextProps.leaguesData.last_page,
            })
        }
    }

    addHandler = (id) => {
        this.setState({ editModalOpen: false, addModalOpen: true });
    }

    closeAddModal = () => {
        this.setState({ addModalOpen: false });
    }

    closeEditModal = () => {
        this.setState({ editModalOpen: false })
    }

    editHandler = (id) => {
        var selected = this.props.leagues.find((t) => {
            return t.id === id;
        });

        this.setState({
            addModalOpen: false,
            editModalOpen: true,
            league: selected
        });
    }

    render() {
        const { classes } = this.props;
        let mappedLeagues = [];
        const leagues = this.props.leagues
        if (leagues !== undefined && leagues.length > 0) {
            mappedLeagues = leagues.map((t) => {
                const statusObj = getStatusObject(t);
                const status = <Badge color={statusObj.statusColor}>{statusObj.statusText}</Badge>;
                const startDate = new Date(t.start_date).toLocaleDateString("en-US");
                const endDate = new Date(t.end_date).toLocaleDateString("en-US");
                const edit = <TooltipButton labelName="Edit" onClick={() => this.editHandler(t.id)}> <Edit /></TooltipButton>
                return {
                    tableData: [t.name, t.id, t.current_game_week, t.last_game_week, startDate, endDate, status, edit],
                    id: t.id
                };
            });
        }
      
        return (
            <div>
                <CustomModal open={this.state.addModalOpen} close={this.closeAddModal}>
                    <AddLeagueForm onClose={this.closeAddModal} />
                </CustomModal>
                <CustomModal open={this.state.editModalOpen} close={this.closeEditModal}>
                    <EditLeagueForm onClose={this.closeEditModal} league={this.state.league} />
                </CustomModal>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>Leagues: </h4>
                                <AddButton labelName="Add League" onClick={() => this.addHandler()} onClose={() => this.closeAddModal()} />
                            </CardHeader>
                            <CardBody>
                                <TableWithActionButtons
                                    tableHeaderColor="gray"
                                    tableHead={["Name", "Number", "Current Game Week", "Last Game Week", "Start", "End", "Status", "    "]}
                                    tableData={mappedLeagues}
                                />
                                 <GridItem xs={12} sm={12} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
                                {this.state.lastPage > 1 ? <Pagination
                                    changePageTo={this.props.getAllLeagues}
                                    currentPage={this.state.page}
                                    totalPages={this.state.lastPage}
                                    color="warning"
                                /> : null}
                            </GridItem>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );

    }
}

const mapStateToProps = state => {
    return {
        leagues: state.leagues.leagues,
        leaguesData: state.leagues.leaguesData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllLeagues: (page) => dispatch(getAllLeagues(page))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LeaguesList));

import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import EditSpecialRoomPredictionModal from "Pages/SpecialRooms/screens/EditSpecialRoomPredictionModal";
import CustomModal from "UI/CustomModal/CustomModal";
import Edit from "@material-ui/icons/Edit";
import TooltipButton from 'UI/CustomButtons/TooltipButton/TooltipButton'
import { getAllSpecialRooms } from 'Pages/SpecialRooms/actions/specialRooms.js';
import { connect } from 'react-redux';
import { darkBlueColor } from 'utils/Constants'

const styles = {

    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    badgeTitle: {
        marginRight: "10px"
    },
    inputIconsColor: {
        color: "lightgray"
    },
};

class ListUserInSpecialRoomPredictions extends React.Component {

    state = {
        editUserPredictionsOpen: false
    }

    editPredictionHandler = (game_id) => {
        const user_id = this.props.match.params.id;
        const room_id = this.props.specialRoom.id;
        const prediction = this.props.specialRoom.participants.find(t => t.user_id == user_id).predictions.find(t => t.game_id == game_id).prediction
        this.setState({
            editUserPredictionsOpen: true,
            user_id,
            game_id,
            room_id,
            prediction
        })
    }

    closePrediction = () => {
        this.setState({
            editUserPredictionsOpen: false,
        });
    }

    render() {
        const { classes } = this.props;
        console.log(this.props.specialRoom.games);
        let mappedPredictions = [];
        if (this.props.specialRoom && this.props.specialRoom.participants && this.props.specialRoom.participants.length > 0) {
            const predictions = this.props.specialRoom.participants.find(t => t.user_id == this.props.match.params.id).predictions;
            mappedPredictions = predictions.map(t => {
                const edit = <TooltipButton labelName="Edit" onClick={() => this.editPredictionHandler(t.game_id)}> <Edit /></TooltipButton>
                return {
                    tableData: [t.home_team_name, t.away_team_name, t.prediction, edit],
                    id: t.game_id
                };
            });
        }

        return (
            <div>
                <CustomModal open={this.state.editUserPredictionsOpen} close={this.closePrediction}>
                    {<EditSpecialRoomPredictionModal
                        user_id={this.state.user_id}
                        room_id={this.state.room_id}
                        game_id={this.state.game_id}
                        prediction={this.state.prediction}
                        onClose={this.closePrediction}
                    />}
                </CustomModal>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>Predictions</h4>
                            </CardHeader>
                            <CardBody>
                                <TableWithActionButtons
                                    tableHeaderColor="gray"
                                    tableHead={["Home", "Away", "Prediction", "    ", "   "]}
                                    tableData={mappedPredictions}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        specialRoom: state.specialRooms.specialRoom
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllSpecialRooms: () => dispatch(getAllSpecialRooms())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ListUserInSpecialRoomPredictions));

import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import CardFooter from "vendor/components/Dashboard/Card/CardFooter.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import CustomInput from "vendor/components/CustomInput/CustomInput.jsx";

import { Scrollbars } from 'react-custom-scrollbars';
import { store } from 'index';
import { showNotification } from 'Toasts/actions/toasts';

import ImageIcon from 'UI/ImageIcon/ImageIcon';
import { getUserInfo, editUser } from 'Pages/Users/actions/users.js';
import { connect } from 'react-redux';
import { darkBlueColor } from 'utils/Constants'
import { TextField } from "@material-ui/core";
import { IMAGE_LARGE } from "../../../utils/Constants";


const styles = {

    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    badgeTitle: {
        marginRight: "10px"
    },
    inputIconsColor: {
        color: "lightgray"
    },
};

class UserInfoList extends React.Component {

    state = {
        balanceWallet: null,
        dateOfBirth: null,
        email: null,
        first_name: null,
        id: null,
        image: null,
        last_name: null,
        nationality: {
            id: null,
            name: null
        },
        gender: {
            id: null,
            name: null
        },
        new_pass: null,
        confirm_new_pass: null
    }

    componentDidMount() {
        this.props.getUserInfo(this.props.userId);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user && nextProps.user !== this.props.user) {
            this.setState({
                balanceWallet: nextProps.user.balanceWallet,
                dateOfBirth: nextProps.user.dateOfBirth,
                email: nextProps.user.email,
                first_name: nextProps.user.first_name,
                id: nextProps.user.id,
                image: nextProps.user.image,
                last_name: nextProps.user.last_name,
                nationality: nextProps.user.nationality[0],
                gender: nextProps.user.sex[0]
            })
        }
    }

    newPassHandler = (pass) => {
        this.setState({
            ...this.state,
            new_pass: pass
        });
    }

    confirmPassHandler = (pass) => {
        this.setState({
            ...this.state,
            confirm_new_pass: pass
        });
    }

    editUserHandler = () => {
        if (!this.state.new_pass || !this.state.confirm_new_pass) {
            store.dispatch(showNotification(
                {
                    type: "error",
                    msg: "Please confirm the password"
                }
            ));
        } else if ((this.state.new_pass !== this.state.confirm_new_pass)) {
            store.dispatch(showNotification(
                {
                    type: "error",
                    msg: "Passwords dont match"
                }
            ));
        } else {
            this.props.editUser({
                id: this.state.id,
                newPassword: this.state.new_pass
            });
            this.props.onClose();
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <GridContainer className={classes.container}>
                    <Card className={classes.card}>

                        <CardHeader color="warning" className={classes.cardHeader}>
                            <h4 className={classes.cardTitle}>Edit User</h4>
                        </CardHeader>
                        <Scrollbars style={{ height: "60vh" }}>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <ImageIcon
                                            big={IMAGE_LARGE}
                                            image={{ url: this.state.image }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} className={classes.grid} />
                                    <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                        <TextField
                                            label="Name"
                                            id="name"
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{
                                                readOnly: true,
                                                value: this.state.first_name + " " + this.state.last_name,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} className={classes.grid} style={{ marginTop: "20px" }}>
                                        <TextField
                                            label="Email"
                                            id="email"
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{
                                                readOnly: true,
                                                value: this.state.email,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} className={classes.grid} style={{ marginTop: "20px" }}>
                                        <TextField
                                            label="Gender"
                                            id="gender"
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{
                                                readOnly: true,
                                                value: this.state.gender ? this.state.gender.name : null,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} className={classes.grid} style={{ marginTop: "20px" }}>
                                        <TextField
                                            label="Nationality"
                                            id="nationality"
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{
                                                readOnly: true,
                                                value: this.state.nationality ? this.state.nationality.name : null,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} className={classes.grid} style={{ marginTop: "20px" }}>
                                        <TextField
                                            label="Wallet Balance"
                                            id="wallet"
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{
                                                readOnly: true,
                                                value: this.state.balanceWallet,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} className={classes.grid} style={{ marginTop: "50px" }} />
                                    <GridItem xs={12} sm={12} md={6} className={classes.grid} >
                                        <CustomInput
                                            labelText="New password"
                                            id={"new_pass"}
                                            fullWidth={true}
                                            labelProps={{ shrink: true }}
                                            inputProps={{
                                                value: this.state.new_pass,
                                                onChange: (event) => this.newPassHandler(event.target.value)
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} className={classes.grid} >
                                        <CustomInput
                                            labelText="Confirm password"
                                            id={"confirm_pass"}
                                            fullWidth={true}
                                            labelProps={{ shrink: true }}
                                            inputProps={{
                                                value: this.state.confirm_new_pass,
                                                onChange: (event) => this.confirmPassHandler(event.target.value)
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>

                            </CardBody>
                        </Scrollbars>
                        <CardFooter className={classes.cardFooter}>
                            <Button
                                onClick={this.editUserHandler}
                                color="success">
                                Edit User
                            </Button>
                        </CardFooter>

                    </Card>
                </GridContainer>
            </div>
        );

    }
}

const mapStateToProps = state => {
    return {
        user: state.users.user
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getUserInfo: (id) => dispatch(getUserInfo(id)),
        editUser: (data) => dispatch(editUser(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserInfoList));

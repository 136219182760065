import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';

import sidebarStyle from "./sidebarStyle.jsx";

class Sidebar extends React.Component {
    state = {
        expanded: null,
        section: null
    };

    // verifies if routeName is the one active (in browser input)
    activeRoute = (routeName) => {
        return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
    }
    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    render() {



        const { classes, color, logo, image, logoText, routes } = this.props;
        const { expanded } = this.state;
        var links = (
            <List className={classes.list}>
                {routes.map((prop, key) => {
                    if (prop.redirect) return null;
                    var activePro = " ";
                    var listItemClasses;
                    if (prop.path === "/upgrade-to-pro") {
                        activePro = classes.activePro + " ";
                        listItemClasses = classNames({
                            [" " + classes[color]]: true
                        });
                    } else {
                        listItemClasses = classNames({
                            [" " + classes[color]]: this.activeRoute(prop.path)
                        });
                    }
                    const whiteFontClasses = classNames({
                        [" " + classes.whiteFont]: this.activeRoute(prop.path)
                    });
                    return (
                        <ExpansionPanel key={key} className={classes.root} expanded={expanded === key} onChange={prop.listItems ? this.handleChange(key) : ""}>
                            <ExpansionPanelSummary className={classes.panelSummary}>
                                <NavLink
                                    to={prop.path}
                                    className={activePro + classes.item}
                                    activeClassName="active"
                                    key={key}
                                >
                                    <ListItem button className={classes.itemLink + listItemClasses}>
                                        <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
                                            {typeof prop.icon === "string" ? (
                                                <Icon>{prop.icon}</Icon>
                                            ) : (
                                                    <prop.icon />
                                                )}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={prop.sidebarName}
                                            className={classes.itemText + whiteFontClasses}
                                            disableTypography={true}
                                        />

                                    </ListItem>

                                </NavLink>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={classes.detailsRoot}>

                                {prop.listItems && prop.listItems.map((p, index) => {
                                    if (p.redirect) return null;
                                    var activePro = " ";
                                    var listItemClasses;
                                    if (p.path === "/upgrade-to-pro") {
                                        activePro = classes.activePro + " ";
                                        listItemClasses = classNames({
                                            [" " + classes[color]]: true
                                        });
                                    } else {
                                        listItemClasses = classNames({
                                            [" " + classes[color]]: this.activeRoute(p.path)
                                        });
                                    }
                                    const whiteFontClasses = classNames({
                                        [" " + classes.whiteFont]: this.activeRoute(p.path)
                                    });
                                    return (

                                        <NavLink
                                            to={p.path}
                                            className={activePro + classes.item}
                                            activeClassName="active"
                                            key={index}
                                        >
                                            <ListItem button className={classes.itemLink + listItemClasses}>
                                                <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
                                                    {typeof p.icon === "string" ? (
                                                        <Icon>{p.icon}</Icon>
                                                    ) : (
                                                            <p.icon />
                                                        )}
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={p.sidebarName}
                                                    className={classes.itemText + whiteFontClasses}
                                                    disableTypography={true}
                                                />

                                            </ListItem>

                                        </NavLink>
                                    );
                                })}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    );
                })}
            </List>
        );
        var brand = (
            <div className={classes.logo}>
                <a href="/" className={classes.logoLink}>
                    {logoText}
                </a>
            </div>
        );
        return (
            <div>
                <Hidden mdUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor="right"
                        open={this.props.open}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        onClose={this.props.handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}
                    >
                        {brand}
                        <div className={classes.sidebarWrapper}>
                            {/* <HeaderLinks /> */}
                            {links}
                        </div>
                        {image !== undefined ? (
                            <div
                                className={classes.background}
                                style={{ backgroundImage: "url(" + image + ")" }}
                            />
                        ) : null}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        anchor="left"
                        variant="permanent"
                        open
                        classes={{
                            paper: classes.drawerPaper
                        }}
                    >
                        {brand}
                        <div className={classes.sidebarWrapper}>{links}</div>
                        {image !== undefined ? (
                            <div
                                className={classes.background}
                                style={{ backgroundImage: "url(" + image + ")" }}
                            />
                        ) : null}
                    </Drawer>
                </Hidden>
            </div>
        );
    }
}

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Sidebar);

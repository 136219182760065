import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components

class UploadImage extends React.Component {

    render() {
        const { classes } = this.props;

        let container = null;

        if (this.props.image) {
            container = <img src={this.props.image} className={classes.pictureSrc} alt="..." />

        }
        if (this.props.video) {
            container = <video width="200rem" height="200rem" controls >
                <source src={this.props.video} type="video/mp4" />
            </video>
        }
        return (
            <div className={classes.pictureContainer}>
                <div className={classes.picture}>
                    <div className={classes.pictureSrc} >
                        <div className={classes.description}>{this.props.description}</div>
                        {container}
                    </div>
                    <input className={classes.input} type="file" required multiple onChange={(event) => this.props.onChange(event)} />
                </div>
            </div >
        );
    };

}

const styles = {

    pictureContainer: {
        width: "100%",
        height: "100%",
        position: "relative",
        cursor: "pointer",
        textAlign: "center",
    },
    picture: {
        // width: "106px",
        // height: "106px",
        width: "100%",
        height: "100%",
        backgroundColor: "grey",
        border: "1px solid #ccc",
        color: "black",
        borderRadius: "5px",
        margin: "5px auto",
        overflow: "hidden",
        transition: "all .2s",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",

    },
    pictureSrc: {
        resizeMode: "stretch",
        maxWidth: "20rem",
        maxHeight: "20rem"
    },
    description: {
        fontSize: ".8em",
        // textTransform: "uppercase",
        fontWeight: "500",

    },
    input: {
        cursor: "pointer",
        display: "block",
        height: "100%",
        left: "0",
        opacity: "0!important",
        position: "absolute",
        top: "0",
        width: "100%"
    },
};




export default withStyles(styles)(UploadImage);

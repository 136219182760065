import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";

import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx"
import OutlinedSelect from "UI/OutlinedSelect/OutlinedSelect";
import Pagination from "UI/Pagination/Pagination";
import ImageIcon from 'UI/ImageIcon/ImageIcon'

import { getAllLeagues, clearAllLeagues } from 'Pages/Leagues/actions/leagues.js';
import { getAllGameStadingsForLeague, clearStandings } from "Pages/GameStandings/actions/gameStandings";
import { connect } from 'react-redux';
import { darkBlueColor } from 'utils/Constants'
import { IMAGE_TINY } from "../../../utils/Constants";

const styles = {
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    selectItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    badgeTitle: {
        marginLeft: "10px"
    }
};

class GameStandingsList extends React.Component {

    state = {
        page: 1,
        lastPage: 1,
        leagueId: null
    }

    componentWillMount() {
        this.props.clearAllLeagues();
        if (!(this.props.leagues !== undefined && this.props.leagues > 0)) {
            this.props.getAllLeagues(1);
        }
    }

    componentWillUnmount() {
        this.props.clearAllLeagues();
        this.props.clearStandings();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.standings !== this.props.standings) {
            this.setState({
                page: nextProps.standings.current_page,
                lastPage: nextProps.standings.last_page,
            })
        }
    }

    handleSelect = (id) => {
        this.setState({
            ...this.state,
            leagueId: id
        });
    }

    filterHandler = (page) => {
        this.props.getAllGameStadingsForLeague({ id: this.state.leagueId, page: page });
    }

    render() {
        const { classes } = this.props;

        let mappedLeagues = [];
        const leagues = this.props.allLeagues;
        if (leagues !== undefined && leagues.length > 0) {
            mappedLeagues = leagues.map(t => {
                return {
                    valueData: [
                        <div key={t.id} className={classes.selectItem}>
                            <ImageIcon big={IMAGE_TINY} image={t.image[0]} />
                            <span className={classes.badgeTitle}>{t.name}</span>
                        </div>
                    ],
                    id: t.id
                };
            });
        }

        let mappedStandings = [];
        const standings = this.props.standings.data;
        if (standings !== undefined && standings.length > 0) {
            mappedStandings = standings.map(t => {
                return {
                    tableData: [t.team_name, t.position, t.match_played, t.goal_difference, t.points],
                    id: t.id
                };
            });
        }

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="warning" className={classes.card}>
                            <h4 className={classes.cardTitle}>Game standings per league:</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer style={{ alignItems: "center" }}>
                                <GridItem xs={12} sm={12} md={6}>
                                    <OutlinedSelect
                                        labelName="League"
                                        selectData={mappedLeagues}
                                        value={this.state.leagueId}
                                        onChange={(event) => this.handleSelect(event)}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={3}>
                                    <Button
                                        round
                                        onClick={() => this.filterHandler(1)}
                                        color="success">
                                        Filter
                                        </Button>
                                </GridItem>
                            </GridContainer>
                            <TableWithActionButtons
                                tableHeaderColor="gray"
                                tableHead={["Team Name", "Position", "Matches Played", "Goal Difference", "Points"]}
                                tableData={mappedStandings}
                            />
                            <GridItem xs={12} sm={12} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
                                {this.state.lastPage > 1 ? <Pagination
                                    changePageTo={this.filterHandler}
                                    currentPage={this.state.page}
                                    totalPages={this.state.lastPage}
                                    color="warning"
                                /> : null}
                            </GridItem>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
        leagues: state.leagues.leagues,
        standings: state.standings.standings,
        allLeagues: state.leagues.allLeagues
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllLeagues: (page) => dispatch(getAllLeagues(page, true)),
        getAllGameStadingsForLeague: (id) => dispatch(getAllGameStadingsForLeague(id)),
        clearStandings: () => dispatch(clearStandings()),
        clearAllLeagues: () => dispatch(clearAllLeagues())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GameStandingsList));

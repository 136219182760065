import * as actionTypes from './actionTypes'
import axios from 'utils/axios'


export const uploadFile = (data) => {
    const fb = new FormData();
    if (data) {
        fb.append('file', data, data.name);
        let settings = { headers: { 'content-type': 'multipart/form-data' } }
        return dispatch => {
            axios.post('/ads/file', fb, settings)
                .then(res => {
                    if (res.data.has_error === false) {
                        dispatch(setUploadFile(res.data.data));
                    }
                });
        };
    }
};

export const setUploadFile = (uploadFile) => {
    return {
        type: actionTypes.UPLOAD_FILE,
        uploadFile: uploadFile
    };
};


export const addAdvert = (data) => {

    return dispatch => {
        axios.post('/ads', data)
            .then(res => {
                if (res.data.has_error === false) {
                    dispatch(getAllAdverts());
                }
            });
    };
};


export const editAdvert = (data) => {

    return dispatch => {
        axios.put('/ads', data)
            .then(res => {
                if (res.data.has_error === false) {
                    dispatch(getAllAdverts());
                }
            });
    };
};


export const getAllAdverts = () => {
    return dispatch => {
        axios.get('/ads')
            .then(res => {
                dispatch(setAllAdverts(res.data.data));
            });
    };
};

export const setAllAdverts = (adverts) => {
    return {
        type: actionTypes.GET_ALL_ADVERTS,
        adverts: adverts
    };
};

export const getAllActions = () => {
    return dispatch => {
        axios.get('/ads/actions')
            .then(res => {
                dispatch(setAllActions(res.data.data));
            });
    };
};

export const setAllActions = (actions) => {
    return {
        type: actionTypes.GET_ALL_ACTIONS,
        actions: actions
    };
};


export const deleteAdvert = (positionId, id) => {
    return dispatch => {
        axios.delete("/ads/" + id)
            .then(res => {
                dispatch(setAdvertDeleted(positionId, id));
            });
    }
}

export const setAdvertDeleted = (positionId, id) => {
    return {
        type: actionTypes.DELETE_ADVERT,
        id: id,
        positionId: positionId
    }
}



export const changeAction = (positionId, actionId) => {
    const data = {
        position_id: positionId,
        action_id: actionId
    };
    return dispatch => {
        axios.put("/ads/position", data)
            .then(res => {
                if (res.data.has_error === false) {
                }
            });
    }
}


export const deleteUploadImage = () => {
    return {
        type: actionTypes.DELETE_UPLOAD_IMAGE
    }
}


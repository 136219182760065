import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getAllGameStadingsForLeague = (data) => {
    return dispatch => {
        axios.get('/standings/leagues/' + data.id, { params: { page: data.page } })
            .then(res => {
                dispatch(setAllStandingsForLeague(res.data.data));
            });
    };
};

export const setAllStandingsForLeague = (standings) => {
    return {
        type: actionTypes.GET_ALL_STANDINGS,
        standings
    };
};

export const clearStandings = () => {
    return {
        type: actionTypes.CLEAR_STANDINGS
    }
}
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";

// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import Badge from "vendor/components/Badge/Badge.jsx"
import Button from "vendor/components/CustomButtons/Button.jsx";

import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx";
import OutlinedSelect from "UI/OutlinedSelect/OutlinedSelect";
import Pagination from "UI/Pagination/Pagination";
import ImageIcon from 'UI/ImageIcon/ImageIcon'
import CustomCheckbox from "UI/CustomCheckbox/CustomCheckbox.js";


import { getAllGames, clearAllGames } from 'Pages/Matches/actions/matches.js';
import { getAllLeagues, clearAllLeagues } from 'Pages/Leagues/actions/leagues.js';
import { getAllGameWeeksForLeague } from "Pages/GameWeeks/actions/gameWeeks.js";

import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { darkBlueColor, getStatusObject } from 'utils/Constants'
import { IMAGE_TINY } from "../../../utils/Constants";

const styles = {

    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    badgeTitle: {
        marginRight: "10px"
    }
};


class AddGamesForSpecialRoom extends React.Component {

    state = {
        lastPage: 1,
        page: 1,
        game: {},
        league: {

        },
        week: {

        },
        matches: [],
        ui_matches: []
    }

    componentWillMount() {
        this.props.clearAllLeagues();
        this.props.clearAllGames();
        if (!(this.props.leagues !== undefined && this.props.leagues > 0)) {
            this.props.getAllLeagues(1);
        }
    }

    addHandler = () => {
        this.props.onClose({ matches: [...this.state.matches], ui_matches: [...this.state.ui_matches] });
    }

    onMatchSelected = (match, event) => {
        if (event.target.checked) {
            this.setState({
                ...this.state,
                ui_matches: this.state.ui_matches.concat({
                    ...match,
                    league_id: this.state.league.id,
                    league_name: this.state.league.name
                }),
                matches: this.state.matches.concat({ league_id: match.league_id, match_id: match.id })
            })
        } else {
            this.setState({
                ...this.state,
                ui_matches: this.state.ui_matches.filter(it => match.id !== it.id),
                matches: this.state.matches.filter(it => match.id !== it.id)
            })
        }
    }

    handleSelect = (id, name) => {
        var selected;
        if (name === "league") {
            selected = this.props.allLeagues.find((t) => {
                return t.id === id;
            });
            this.props.getAllGameWeeksForLeague(id);
        } else {
            selected = this.props.weeks.find((t) => {
                return t.id === id;
            });
        }

        this.setState({
            ...this.state,
            [name]: selected

        });
    }

    filterHandler = () => {
        let data = {
            ...this.state,
            page: 1,
        };
        this.props.getAllGames(data);
    }

    clearFilters = () => {
        let data = {
            lastPage: 1,
            page: 1,
            league: {

            },
            week: {

            }
        };
        this.setState({ ...data });
    }

    render() {
        const { classes } = this.props;
        let mappedLeagues = [];
        const leagues = this.props.allLeagues
        if (leagues !== undefined && leagues.length > 0) {
            mappedLeagues = leagues.map(t => {
                return {
                    valueData: [
                        <div key={t.id} className={classes.selectItem}>
                            <ImageIcon big={IMAGE_TINY} image={t.image[0]} />
                            <span className={classes.badgeTitle}>{t.name}</span>
                        </div>
                    ],
                    id: t.id
                };
            });
        }

        let mappedGames = [];
        const games = this.props.allGames;
        if (games !== undefined && games.length > 0) {
            mappedGames = games
                .filter(t => {
                    const matchMoment = moment(t.match_date + t.match_time, 'YYYY-MM-DD hh:mm ');
                    const roomStart = moment(this.props.startDate + "00:00", 'YYYY-MM-DD hh:mm ');
                    const roomEnd = moment(this.props.endDate + "23:59", 'YYYY-MM-DD hh:mm ');
                    return matchMoment.isBetween(roomStart, roomEnd) && t.match_status === "Upcoming"
                })
                .map(t => {
                    const startTime = "" + new Date(t.match_date).toLocaleDateString("en-US") + ", " + t.match_time;
                    const hostLogo = <ImageIcon image={{ url: t.home_team_badge }} />
                    const guestLogo = <ImageIcon image={{ url: t.away_team_badge }} />
                    const checkBox = <CustomCheckbox onChange={(event) => this.onMatchSelected(t, event)} />
                    return {
                        tableData: [startTime, t.home_team_name, hostLogo, guestLogo, t.away_team_name, checkBox],
                        id: t.id
                    };
                });
        }

        let mappedGameWeeks = [];
        let weeks = this.props.weeks;
        if (weeks !== undefined && weeks.length > 0) {
            weeks = weeks.filter(t => {
                return (moment(this.props.startDate + "00:00", 'YYYY-MM-DD hh:mm ').isBetween(moment(t.start_date + "00:00", 'YYYY-MM-DD hh:mm '), moment(t.end_date + "23:59", 'YYYY-MM-DD hh:mm '))
                    || moment(this.props.endDate + "23:59", 'YYYY-MM-DD hh:mm ').isBetween(moment(t.start_date), moment(t.end_date + "23:59", 'YYYY-MM-DD hh:mm '))) ||
                    (moment(t.start_date + "00:00", 'YYYY-MM-DD hh:mm ').isBetween(moment(this.props.startDate + "00:00", 'YYYY-MM-DD hh:mm '), moment(this.props.endDate + "23:59", 'YYYY-MM-DD hh:mm '))
                        || moment(t.end_date + "23:59", 'YYYY-MM-DD hh:mm ').isBetween(moment(this.props.startDate + "00:00", 'YYYY-MM-DD hh:mm '), moment(this.props.endDate + "23:59", 'YYYY-MM-DD hh:mm ')))
            })
            mappedGameWeeks = weeks.map(t => {
                const status = getStatusObject(t);
                return {
                    valueData: [
                        <div key={t.id} className={classes.selectItem}>
                            <span className={classes.badgeTitle}>{"Week " + (t.number) + "  "}</span>
                            <Badge color={status.statusColor} >{status.statusText}</Badge>
                        </div>
                    ],
                    id: t.id
                }
            });
        }

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>Matches per League:</h4>
                            </CardHeader>
                            <Scrollbars style={{ height: "60vh" }}>
                                <CardBody>

                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <OutlinedSelect
                                                labelName="League"
                                                selectData={mappedLeagues}
                                                value={this.state.league.id}
                                                onChange={(event) => this.handleSelect(event, "league")}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <OutlinedSelect
                                                labelName="Game Week"
                                                selectData={mappedGameWeeks}
                                                value={this.state.week.id}
                                                onChange={(event) => this.handleSelect(event, "week")}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={6}>
                                            <Button
                                                round
                                                onClick={this.filterHandler}
                                                color="success">
                                                Filter
                                        </Button>
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={6}>
                                            <Button
                                                round
                                                onClick={this.clearFilters}
                                                color="danger">
                                                Clear Filters
                                        </Button>
                                        </GridItem>
                                    </GridContainer>

                                    <TableWithActionButtons
                                        tableHeaderColor="gray"
                                        tableHead={["Start Time", "Home", "   ", "   ", "Away", "   ", "   "]}
                                        tableData={mappedGames}
                                    />
                                    <Button
                                        color="success"
                                        onClick={() => this.addHandler()}
                                    >
                                        Add Selected
                                    </Button>
                                    <GridItem xs={12} sm={12} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        {this.state.lastPage > 1 ? <Pagination
                                            changePageTo={this.onChangePage}
                                            currentPage={this.state.page}
                                            totalPages={this.state.lastPage}
                                            color="warning"
                                        /> : null}
                                    </GridItem>
                                </CardBody>
                            </Scrollbars>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        leagues: state.leagues.leagues,
        allLeagues: state.leagues.allLeagues,
        games: state.games.games.data,
        allGames: state.games.allGames,
        weeks: state.weeks.weeks
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllLeagues: (page) => dispatch(getAllLeagues(page, true)),
        getAllGames: (data) => dispatch(getAllGames(data, true)),
        getAllGameWeeksForLeague: (id) => dispatch(getAllGameWeeksForLeague(id)),
        clearAllLeagues: () => dispatch(clearAllLeagues()),
        clearAllGames: () => dispatch(clearAllGames())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddGamesForSpecialRoom));

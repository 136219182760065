// @material-ui/icons
import Person from '@material-ui/icons/Person'
import Home from '@material-ui/icons/Home'
import EventNote from '@material-ui/icons/EventNote'
import DirectionsRun from '@material-ui/icons/DirectionsRun'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import Grade from '@material-ui/icons/Grade'
import Message from '@material-ui/icons/Message'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import Image from '@material-ui/icons/Image'
// core components/views

import LeaguesList from 'Pages/Leagues/screens/LeaguesList'
import GamesList from 'Pages/Matches/screens/GamesList'
import UsersList from 'Pages/Users/screens/UsersList'
import GameWeeksList from 'Pages/GameWeeks/screens/GameWeeksList'
import GameRoomsList from 'Pages/GameRooms/screens/GameRoomsList'
import GameStandingsList from 'Pages/GameStandings/screens/GameStandingsList'
import SpecialRoomsList from 'Pages/SpecialRooms/screens/SpecialRoomsList'
import Notifications from 'Pages/Notifications/screens/Notifications'
import AdvertismentsList from 'Pages/Advertisements/screens/AdvertismentsList'

const adminRoutes = [
  {
    path: '/leagues',
    sidebarName: 'Leagues',
    navbarName: 'Leagues',
    icon: Grade,
    component: LeaguesList,
  },
  {
    path: '/games',
    sidebarName: 'Games',
    navbarName: 'Games',
    icon: DirectionsRun,
    component: GamesList,
  },
  {
    path: '/users',
    sidebarName: 'Users',
    navbarName: 'Users',
    icon: Person,
    component: UsersList,
  },
  {
    path: '/weeks',
    sidebarName: 'Game Weeks',
    navbarName: 'Game Weeks',
    icon: EventNote,
    component: GameWeeksList,
  },
  {
    path: '/rooms',
    sidebarName: 'Rooms',
    navbarName: 'Rooms',
    icon: SupervisorAccountIcon,
    component: GameRoomsList,
  },
  {
    path: '/standings',
    sidebarName: 'Standings',
    navbarName: 'Standings',
    icon: ShowChartIcon,
    component: GameStandingsList,
  },
  {
    path: '/advertisement',
    sidebarName: 'Advertisement',
    navbarName: 'Advertisement',
    icon: Image,
    component: AdvertismentsList,
  },
  {
    path: '/specialRooms',
    sidebarName: 'Special Games',
    navbarName: 'Special Games',
    icon: GroupAddIcon,
    component: SpecialRoomsList,
  },
  {
    path: '/notifications',
    sidebarName: 'Notifications',
    navbarName: 'Notifications',
    icon: Message,
    component: Notifications,
  },

  { redirect: true, path: '/', to: '/leagues', navbarName: 'Redirect' },
]

export default adminRoutes

import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CardFooter from "vendor/components/Card/CardFooter.jsx";
import { successColor } from "vendor/assets/jss/material-dashboard-react.jsx";
import CustomInput from "vendor/components/CustomInput/CustomInput.jsx";
import UploadImage from "UI/UploadImage/UploadImage"
import CustomPicker from "UI/CustomPicker/CustomPicker";
import { Scrollbars } from 'react-custom-scrollbars';
import { darkBlueColor } from "utils/Constants"
import { editLeague } from "Pages/Leagues/actions/leagues"
import axios from "utils/axios";
import { connect } from 'react-redux';

const START_DATE = "start_date";
const END_DATE = "end_date";

class EditLeagueForm extends React.Component {

    state = {
        league_id: null,
        image: null,
        name: null,
        start_date: null,
        end_date: null
    }
    componentWillMount() {
        console.log(this.props.league);
        this.setState({
            league_id: this.props.league.id,
            image: this.props.league.image[0],
            name: this.props.league.name,
            start_date: this.props.league.start_date,
            end_date: this.props.league.end_date
        });
    }

    nameChangeHandler = (event) => {
        this.setState({name: event.target.value})
    }

    dateChangedHandler = (date, propName) => {
        const formattedDate = date.format("YYYY-MM-DD HH:mm");
        this.setState({ [propName]: formattedDate });
    }

    editHandler = () => {
        console.log(this.state);
        this.props.editLeague(this.state)
        this.props.onClose()
    }

    imageChangedHandler = (event) => {
        if (!event.target.files[0]) {
            return
        }
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (event) {
            var image = event.target.result;
            image = image.slice(image.indexOf(',') + 1);
            axios.post('/images', { base64: image }).then((response) => {
                this.setState({ image: response.data.data });
            });
        }.bind(this);
    }

    render() {
        const { classes } = this.props;

        return (
            <div>

                <GridContainer className={classes.container}>
                    <Card className={classes.card}>

                        <CardHeader color="warning" className={classes.cardHeader}>
                            <h4 className={classes.cardTitle}>Edit league</h4>
                        </CardHeader>
                        <Scrollbars style={{ height: "60vh" }}>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <UploadImage
                                            description="Upload league image"
                                            image={this.state.image}
                                            onChange={this.imageChangedHandler}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} className={classes.grid}/>
                                    <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                        <CustomInput
                                            labelText="Name"
                                            id="name"
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                value: this.state.name,
                                                onChange: (event) => this.nameChangeHandler(event)
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} className={classes.grid}/>
                                    
                                    <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                        <CustomPicker
                                            labelName="Start date"
                                            onChange={(event) => this.dateChangedHandler(event, START_DATE)}
                                            date={this.state.start_date}

                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                        <CustomPicker
                                            labelName="End date"
                                            onChange={(event) => this.dateChangedHandler(event, END_DATE)}
                                            date={this.state.end_date}

                                        />
                                    </GridItem>
                                </GridContainer>

                            </CardBody>
                        </Scrollbars>
                        <CardFooter className={classes.cardFooter}>
                            <Button
                                onClick={this.editHandler}
                                color="success">
                                Edit League
                            </Button>
                        </CardFooter>

                    </Card>
                </GridContainer>
            </div>
        );
    };

}

const styles = theme => ({
    grid: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginTop: "10px",
        marginBottom: "10px"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none"
    },
    card: {
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "0",
        flexBasis: "100%"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    cardHeader: {
        width: "100%",
        zIndex: "10"
    },
    cardFooter: {
        zIndex: "10",
        background: "white",
        borderTop: "none",
        [theme.breakpoints.down("sm")]: {
            borderTop: "1px solid " + successColor
        },
    },
    selectItem: {
        display: "flex",
        alignItems: "center",
    },
    badgeTitle: {
        marginRight: "10px"
    }
});

const mapDispatchToProps = dispatch => {
    return {
        editLeague: (data) => dispatch(editLeague(data))
    }
}


export default connect(null, mapDispatchToProps)(withStyles(styles)(EditLeagueForm));

import React, { Component } from "react";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import Button from "vendor/components/CustomButtons/Button.jsx";

export default class CustomToggleButton extends Component {

    state = {
        pressed: false
    }

    toggleFilter = () => {
        this.props.toggleFilter(!this.state.pressed)
        this.setState({pressed: !this.state.pressed})
    }

    render() {
        const color = this.state.pressed ? "green" : "red";
        return (
            
            <Button
            style={{background: color }}
            value="check"
            selected={this.state.pressed}
            onClick={() => {
                this.toggleFilter()
            }}
          >
            {this.state.pressed ? <LockOpenOutlinedIcon /> : <LockOutlinedIcon />}
          </Button>
           
            
        )
    }
}
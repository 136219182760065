export const BASE_URL = 'https://api2.glowter.com';
export const darkBlueColor = "#14182B";
export const darkYellowColor = "#DE9222";
export const blueColor = "#49A9EA";

export const blueBoxShadow = {
    boxShadow:
        "0 12px 20px -10px rgba(73,169,234, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(73,169,234, 0.2)"
};

export const convertDateWithTime = (date) => {
    //TO DO - when language is switch to english change locale
    var options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: "numeric", minute: "numeric" };
    let d = new Date();
    if (date) {
        d = new Date(date.replace(/-/g, "/"));
    }

    let format = "bg-BG";

    return d.toLocaleDateString(format, options)
}

export const convertDate = (date) => {
    //TO DO - when language is switch to english change locale
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    let d = new Date();
    if (date) {
        d = new Date(date.replace(/-/g, "/"));
    }

    let format = "bg-BG";

    return d.toLocaleDateString(format, options)
}

export const convertTime = (date) => {
    //TO DO - when language is switch to english change locale
    var options = { hour: "numeric", minute: "numeric" };
    let d = new Date();
    if (date) {
        d = new Date(date.replace(/-/g, "/"));
    }

    let format = "bg-BG";

    return d.toLocaleTimeString(format, options)
}

export const sortTypes = [
    {
        id: 1,
        name: "Descending",
        key: "desc"
    },
    {
        id: 2,
        name: "Ascending",
        key: "asc"
    }
];

export const sortValues = [
    {
        id: 1,
        name: "First Name",
        key: "first_name"
    },
    {
        id: 2,
        name: "Email",
        key: "email"
    }
];

export const getAdvertStatus = (t) => {
    let statusColor;
    let statusText;
    if (t.is_active) {
        statusColor = "success"
        statusText = "Active"
    } else {
        statusColor = "danger"
        statusText = "Inactive"
    }

    return {
        statusText, statusColor,
    }
}

export const getStatusObject = (t) => {
    let statusColor;
    let statusText;
    let isOver;
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    if (new Date(t.start_date) <= now && new Date(t.end_date) >= now) {
        statusColor = "warning"
        statusText = "Running"
        isOver = false;
    } else if (new Date(t.start_date) > now) {
        statusColor = "success"
        statusText = "Upcoming"
        isOver = false;
    } else {
        statusColor = "danger"
        statusText = "Over"
        isOver = true;
    }
    return {
        statusText, statusColor, isOver
    }
}

export const getMatchStatusColor = (match) => {
    switch (match.match_status) {
        case "Running": {
            return "warning";
        }
        case "Upcoming": {
            return "success";
        }
        case "Finished": {
            return "danger";
        }
    }
}

export const getMatchStatus = (game) => {
    switch (game.status) {

    }
}

export const IMAGE_TINY = 0;
export const IMAGE_REGULAR = 1;
export const IMAGE_LARGE = 2;
import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getAllSpecialRooms = () => {
    return dispatch => {
        dispatch(clearForAdd())
        axios.get('/specialroom')
            .then(res => {
                dispatch(setAllSpecialRooms(res.data.data));
            });
    };
};

export const setAllSpecialRooms = (rooms) => {
    return {
        type: actionTypes.GET_ALL_SPECIAL_ROOMS,
        specialRooms: rooms
    };
};

export const clearForAdd = () => {
    return {
        type: actionTypes.CLEAR_FOR_ADD_SPECIAL_ROOM
    }
}

export const addSpecialRoom = (data) => {
    return dispatch => {
        axios.post('/specialroom', data)
            .then(res => {
                dispatch(setSpecialRoomAdded(res.data.data));
            })
    }
}

export const setSpecialRoomAdded = (room) => {
    return {
        type: actionTypes.ADD_SPECIAL_ROOM,
        special_added: room
    }
}

export const editSpecialRoom = (id, data) => {
    return dispatch => {
        axios.put('/specialroom/' + id, data)
            .then(res => {
                dispatch(setSpecialRoomEditted(res.data.data));
            })
    }
}

export const setSpecialRoomEditted = (room) => {
    return {
        type: actionTypes.EDIT_SPECIAL_ROOM,
        special_edited: room
    }
}

export const getSpecialRoom = (id) => {
    return dispatch => {
        axios.get('/specialroom/' + id)
            .then(res => {
                dispatch(setSpecialRoom(res.data.data));
            });
    }
}

export const setSpecialRoom = (specialRoom) => {
    return {
        type: actionTypes.GET_SPECIAL_ROOM,
        specialRoom
    }
}

export const editSpecialRoomPrediction = (data) => {
    return dispatch => {
        axios.put('/special/prediction', data)
        .then(res => {
            dispatch(setSpecialRoomPredictionEdited(data));
        });
    }
}

export const setSpecialRoomPredictionEdited = (data) => {
    return {
        type: actionTypes.EDIT_SPECIAL_ROOM_PREDICTION,
        editedSpecialPrediction: data
    }
}
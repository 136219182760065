import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import Badge from "vendor/components/Badge/Badge.jsx"
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CardFooter from "vendor/components/Card/CardFooter.jsx";
import CustomSelect from "UI/CustomSelect/CustomSelect";
import CustomTextField from "vendor/components/CustomTextField/CustomTextField.jsx"
import CustomPicker from "UI/CustomPicker/CustomPicker";
import ImageIcon from "UI/ImageIcon/ImageIcon"
import { Scrollbars } from 'react-custom-scrollbars';
import { successColor } from "vendor/assets/jss/material-dashboard-react.jsx";
import { darkBlueColor, getStatusObject } from "utils/Constants"
import { getAllTeamsForChampionship, addGame } from 'Pages/Matches/actions/matches.js';
import { getAllGameWeeksForLeague } from "Pages/GameWeeks/actions/gameWeeks";
import { getAllLeagues } from "Pages/Leagues/actions/leagues";
import moment from "moment";

import { connect } from 'react-redux';
import { IMAGE_TINY } from "../../../utils/Constants";

const LEAGUE = "league";
const HOST = "host";
const GUEST = "guest";
const WEEK = "week";
const SHIELD = "shield"
const HOME_SCORE = "homeScore";
const AWAY_SCORE = "awayScore";
const HOME_FILTERED_TEAMS = "homeTeams";
const AWAY_FILTERED_TEAMS = "awayTeams";

class AddGameForm extends React.Component {

    state = {
        league: 1,
        host: {},
        guest: {},
        shield: 1,
        homeScore: 1,
        awayScore: 1,
        week: {},
        start_date: null,
        homeTeams: [],
        awayTeams: []
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.teams !== this.props.teams) {
            this.setState({
                ...this.state,
                homeTeams: [...nextProps.teams],
                awayTeams: [...nextProps.teams]
            });
        }
    }

    handleSelect = (id, name) => {
        var selected;
        switch (name) {
            case LEAGUE: {
                selected = this.props.allLeagues.find((t) => {
                    return t.id === id;
                });
                this.props.getAllGameWeeksForLeague(id);
                this.props.getAllTeamsForChampionship(id);
                this.setState({
                    ...this.state,
                    [name]: selected
        
                });
                break;
            }
            
            case WEEK: {
                selected = this.props.weeks.find((t) => {
                    return t.id === id;
                });
                this.setState({
                    ...this.state,
                    [name]: selected
        
                });
                break;
            }
            
            case GUEST: case HOST: {
                selected = this.props.teams.find((t) => {
                    return t.team_id === id;
                });
                let selectedArray;
                let otherArray;
                if (name === GUEST) {
                    selectedArray = HOME_FILTERED_TEAMS;
                    otherArray = AWAY_FILTERED_TEAMS;
                } else {
                    selectedArray = AWAY_FILTERED_TEAMS;
                    otherArray = HOME_FILTERED_TEAMS;
                }
                this.setState({
                    ...this.state,
                    [name]: selected,
                    [selectedArray]: this.state[selectedArray].filter(t=> t.team_id !== selected.team_id),
                    [otherArray]: [...this.props.teams]
                });
                break;
            }

            case SHIELD: case AWAY_SCORE: case HOME_SCORE: {
                selected = id;
                this.setState({
                    ...this.state,
                    [name]: selected
        
                });
                break;
            }

            default: {
                // Empty
            }
        }
        
    }

    dateChangedHandler = (date) => {
        const start_date = date.format("YYYY-MM-DD HH:mm:ss");
        this.setState({ start_date: start_date });
    }

    addGameHandler = () => {
        
        const match_date = moment(new Date(this.state.start_date)).format("YYYY-MM-DD");
        const options = {
            hour12 : false,
            hour:  "2-digit",
            minute: "2-digit"
         }
        const match_time = new Date(this.state.start_date).toLocaleTimeString("en-US", options);
        const data = {
            league_id: this.state.league.id,
            home_team_id: this.state.host.team_id,
            home_team_score: this.state.homeScore, 
            away_team_id: this.state.guest.team_id,
            away_team_score: this.state.awayScore,
            match_time: match_time,
            match_date: match_date,
            match_shield: this.state.shield,
            game_week: this.state.week.number
        }
        
        this.props.addGame(data)
        this.props.onClose()
    }

    mapTeams = (teamsArrayName, classes) => {
        if (this.props.teams.length > 0) {
            return this.state[teamsArrayName].map(t => {
                return {
                    valueData: [
                        <div key={t.team_id} className={classes.selectItem}>
                            <ImageIcon image={{ url: t.team_badge }} />
                            {t.team_name}
                        </div>
                    ],
                    id: t.team_id
                };
            });
        } else return [];
    }


    render() {
        const { classes } = this.props;

        let mappedHomeTeams = this.mapTeams(HOME_FILTERED_TEAMS, classes);
        let mappedAwayTeams = this.mapTeams(AWAY_FILTERED_TEAMS, classes);

        let mappedLeagues = [];
        const leagues = this.props.allLeagues
        if (leagues !== undefined && leagues.length > 0) {
            mappedLeagues = leagues.map(t => {
                return {
                    valueData: [
                        <div key={t.id} className={classes.selectItem}>
                            <ImageIcon big={IMAGE_TINY} image={t.image[0]} />
                            <span className={classes.badgeTitle}>{t.name}</span>
                        </div>
                    ],
                    id: t.id
                };
            });
        }

        let mappedWeeks = [];
        if (this.props.weeks.length > 0) {
            mappedWeeks = this.props.weeks.map(t => {
                const status = getStatusObject(t);
                return {
                    valueData: [
                        <div key={t.id} className={classes.selectItem}>
                            <span className={classes.badgeTitle}>{"Week " + t.number + " "}</span>
                            <Badge color={status.statusColor}>{status.statusText}</Badge>
                        </div>
                    ],
                    id: t.id
                };
            });
        }

        return (

            <GridContainer className={classes.container}>
                <Card className={classes.card}>
                    <CardHeader color="warning" className={classes.cardHeader}>
                        <h4 className={classes.cardTitle}>Add game</h4>
                    </CardHeader>
                    <Scrollbars style={{ height: "60vh" }}>
                        <CardBody>
                            <GridContainer style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <CustomSelect
                                        labelName="League"
                                        selectData={mappedLeagues}
                                        value={this.state.league.id}
                                        fullWidth={true}
                                        onChange={(event) => this.handleSelect(event, LEAGUE)}

                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <CustomSelect
                                        labelName="Week"
                                        selectData={mappedWeeks}
                                        value={this.state.week.id}
                                        fullWidth={true}
                                        onChange={(event) => this.handleSelect(event, WEEK)}

                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <CustomSelect
                                        labelName="Home"
                                        selectData={mappedHomeTeams}
                                        value={this.state.host.team_id}
                                        fullWidth={true}
                                        onChange={(event) => this.handleSelect(event, HOST)}

                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <CustomSelect
                                        labelName="Away"
                                        selectData={mappedAwayTeams}
                                        onChange={(event) => this.handleSelect(event, GUEST)}
                                        fullWidth={true}
                                        value={this.state.guest.team_id}

                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomTextField
                                        inputType="number"
                                        labelText="Home team score"
                                        id={HOME_SCORE}
                                        fullWidth={true}
                                        inputProps={{
                                            value: this.state.homeScore,
                                            onChange: (event) => this.handleSelect(event.target.value, HOME_SCORE)
                                        }}

                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomTextField
                                        inputType="number"
                                        labelText="Away team score"
                                        id={HOME_SCORE}
                                        fullWidth={true}
                                        inputProps={{
                                            value: this.state.awayScore,
                                            onChange: (event) => this.handleSelect(event.target.value, AWAY_SCORE)
                                        }}

                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <CustomPicker
                                        labelName="Start time"
                                        onChange={this.dateChangedHandler}
                                        date={this.state.start_date}
                                        fullWidth={true}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomTextField
                                        inputType="number"
                                        labelText="Shield"
                                        id={SHIELD}
                                        fullWidth={true}
                                        inputProps={{
                                            value: this.state.shield,
                                            onChange: (event) => this.handleSelect(event.target.value, SHIELD)
                                        }}

                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Scrollbars>
                    <CardFooter className={classes.cardFooter}>
                        <Button
                            onClick={this.addGameHandler}
                            color="success">
                            Add game
                            </Button>
                    </CardFooter>
                </Card>
            </GridContainer>

        );
    };

}

const styles = theme => ({
    grid: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none"
    },
    card: {
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "0",
        flexBasis: "100%"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    cardHeader: {
        width: "100%",
        zIndex: "10"
    },
    cardFooter: {
        zIndex: "10",
        background: "white",
        borderTop: "none",
        [theme.breakpoints.down("sm")]: {
            borderTop: "1px solid " + successColor
        },
    },
    selectItem: {
        display: "flex",
        alignItems: "center",
    },
    badgeTitle: {
        marginRight: "10px"
    }
});

const mapStateToProps = state => {
    return {
        teams: state.games.teams,
        weeks: state.weeks.weeks,
        leagues: state.leagues.leagues,
        allLeagues: state.leagues.allLeagues
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllTeamsForChampionship: id => dispatch(getAllTeamsForChampionship(id)),
        addGame: data => dispatch(addGame(data)),
        getAllGameWeeksForLeague: (id) => dispatch(getAllGameWeeksForLeague(id)),
        getAllLeagues: (page) => dispatch(getAllLeagues(page, true)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddGameForm));

import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Edit from '@material-ui/icons/Edit';
// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import TooltipButton from 'UI/CustomButtons/TooltipButton/TooltipButton';
import CustomModal from "UI/CustomModal/CustomModal";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import ImageIcon from 'UI/ImageIcon/ImageIcon';
import { getUserPredictions } from 'Pages/Users/actions/users.js';
import { connect } from 'react-redux';
import { darkBlueColor } from 'utils/Constants'
import EditPredictionModal from "./EditPredictionModal";

const styles = {

    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    badgeTitle: {
        marginRight: "10px"
    },
    inputIconsColor: {
        color: "lightgray"
    },
};

class UserPredictionsList extends React.Component {

    state = {
        editBetModal: false,
        user_id: null,
        bet: null,

    }

    componentDidMount() {
        this.props.getUserPredictions(this.props.match.params.id);
    }

    editHandler = (id) => {
        this.setState({
            editBetModal: true,
            user_id: this.props.match.params.id,
            bet: this.props.predictions.find(t => t.game_id === id).prediction,
            matchId: id
        });
    }

    closeBetModal = () => {
        this.setState({
            editBetModal: false
        });
    }

    render() {
        const { classes } = this.props;

        let mappedPredictions = [];
        const predictions = this.props.predictions;
        if (predictions !== undefined && predictions.length > 0) {
            mappedPredictions = predictions.map(t => {
                const startDate = new Date(t.start_date).toLocaleDateString("en-US");
                const leagueLogo = <ImageIcon image={t.league_image} />
                const hostLogo = <ImageIcon image={{ url: t.home_team_image }} />
                const guestLogo = <ImageIcon image={{ url: t.away_team_badge }} />
                const edit = <TooltipButton labelName="Edit" onClick={() => this.editHandler(t.game_id)}> <Edit /></TooltipButton>

                return {
                    tableData: [leagueLogo, t.league_name, startDate, t.home_team_name, hostLogo, guestLogo, t.away_team_name, t.prediction, edit],
                    id: t.game_id
                };
            });
        }

        return (
            <div>
                <CustomModal open={this.state.editBetModal} close={this.closeEditModal}>
                    {<EditPredictionModal
                        user_id={this.state.user_id}
                        matchId={this.state.matchId}
                        bet={this.state.bet}
                        onClose={this.closeBetModal}
                    />}
                </CustomModal>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>Predictions for User: {this.props.match.params.user_name} </h4>
                            </CardHeader>
                            <CardBody>
                                <TableWithActionButtons
                                    tableHeaderColor="gray"
                                    tableHead={["   ", "League", "Start Date", "Home Team", "   ", "   ", "Away Team", "Prediction", "   "]}
                                    tableData={mappedPredictions}

                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );

    }
}

const mapStateToProps = state => {
    return {
        predictions: state.users.predictions
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getUserPredictions: (id) => dispatch(getUserPredictions(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserPredictionsList));

import {
    GET_ALL_LEAGUES,
    GET_ALL_AVAILABLE_LEAGUES,
    ADD_LEAGUE,
    EDIT_LEAGUE,
    CLEAR_ALL_LEAGUES
} from 'Pages/Leagues/actions/actionTypes';

const initialState = {
    leagues: [],
    apiLeagues: [],
    leaguesData: [],
    allLeagues: []
}

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_ALL_LEAGUES:
            return {
                ...state,
                leagues: action.leagues.data,
                leaguesData: action.leagues,
                allLeagues: state.allLeagues.concat(action.leagues.data)
            }
        case CLEAR_ALL_LEAGUES: 
        return {
            ...state,
            allLeagues: []
        }
        case GET_ALL_AVAILABLE_LEAGUES: {
            return {
                ...state,
                apiLeagues: action.apiLeagues
            }
        }

        case ADD_LEAGUE: {
            return {
                ...state,
                leagues: state.leagues.concat({
                    league_api_id: action.league.id,
                    name: action.league.name,
                    start_date: action.league.start_day_date,
                    end_date: action.league.end_day_date,
                    image: [action.requestData.image]
                })
            }
        }

        case EDIT_LEAGUE: {
            return {
                ...state,
                leagues: state.leagues.map(it =>{
                    if (it.id === action.league.id) {
                        return {
                            ...it,
                            league_api_id: action.league.id,
                            name: action.league.name,
                            start_date: action.league.start_date,
                            end_date: action.league.end_date,
                            image: [action.requestData.image]
                        }
                    }
                    return {...it}
                })
            }
        }

        default:
            return state;
    }
}
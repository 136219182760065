import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Table from "UI/Table/Table.jsx"
import Badge from "vendor/components/Badge/Badge.jsx"

import OutlinedSelect from "UI/OutlinedSelect/OutlinedSelect";
import ImageIcon from 'UI/ImageIcon/ImageIcon'
import ConfirmModal from "UI/ConfirmModal/ConfirmModal"


import { getAllAdverts, deleteAdvert, getAllActions, changeAction } from "Pages/Advertisements/actions/advertisements"
import { connect } from 'react-redux';
import { darkBlueColor } from 'utils/Constants'
import { IMAGE_LARGE, getAdvertStatus, BASE_URL } from "../../../utils/Constants";

import CustomModal from "UI/CustomModal/CustomModal"
import AddAdvertismentsForm from "./AddAdvertismentsForm";
import EditAdvertismentsForm from "./EditAdvertismentsForm";



const styles = {
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    selectItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    badgeTitle: {
        marginLeft: "10px"
    },

};

class AdvertismentsList extends React.Component {

    state = {
        page: 1,
        lastPage: 1,
        advertId: null,
        addModalOpen: false,
        confirmModalOpen: false,
        confirmMessage: null,
        actionId: 1,
        advertisement: [],
    }

    addHandler = (id) => {
        this.setState({ editModalOpen: false, addModalOpen: true });
    }

    closeAddModal = () => {
        this.setState({ addModalOpen: false });
    }

    openConfirmDeleteHandler = (id) => {
        this.setState({
            confirmModalOpen: true,
            confirmMessage: "Are you sure you want to delete this advert?",
            deleteId: id
        })
    }

    deleteHandler = (id) => {
        this.setState({ confirmModalOpen: false });
        this.props.deleteAdvert(this.state.advertId, id);
    }

    closeConfirmModalHandler = (id) => {
        this.setState({ confirmModalOpen: false });
    }


    componentWillMount() {
        this.props.getAllAdverts();
        this.props.getAllActions();
    }
    handleSelect = (id) => {
        const advertisement = this.props.adverts.filter(ad => ad.position_id === id);
        this.setState({
            ...this.state,
            advertId: id,
            actionId: advertisement[0].action.id
        });
    }

    handleActionSelect = (id) => {
        this.props.changeAction(this.state.advertId, id)
        this.setState({
            ...this.state,
            actionId: id,
        })
    }



    editHandler = (id) => {
        const advertisement = this.props.adverts.filter(ad => ad.position_id === this.state.advertId);


        var selected = advertisement[0].advertisements.find((t) => {

            return t.id === id;
        });

        this.setState({
            addModalOpen: false,
            editModalOpen: true,
            advert: selected,
        });
    }

    closeEditModal = () => {
        this.setState({ editModalOpen: false });
    }

    render() {
        const { classes, actions, adverts } = this.props;
        const { advertId } = this.state;

        let mappedAdverts = [];
        if (adverts !== undefined && adverts.length > 0) {
            mappedAdverts = adverts.map(t => {
                return {
                    valueData: [
                        <div key={t.position_id} className={classes.selectItem}>
                            <span className={classes.badgeTitle}>{t.name} Advertisements</span>
                        </div>
                    ],
                    id: t.position_id
                };
            });
        }

        let mappedActions = [];
        if (actions !== undefined && actions.length > 0) {
            mappedActions = actions.map(t => {
                return {
                    valueData: [
                        <div key={t.id} className={classes.selectItem}>
                            <span className={classes.badgeTitle}>{t.name}</span>
                        </div>
                    ],
                    id: t.id
                };
            });
        }

        let mappedAdvertisments = [];
        const advertisement = this.props.adverts.filter(ad => ad.position_id === advertId);
        if (advertisement && advertisement.length !== 0 && advertisement[0].advertisements !== undefined && advertisement[0].advertisements.length > 0) {
            mappedAdvertisments = advertisement[0].advertisements.map(t => {
                const statusojb = getAdvertStatus(t);
                const status = <Badge color={statusojb.statusColor}>{statusojb.statusText}</Badge>;
                const image = <ImageIcon image={t.image ? { url: t.image.url } : { url: t.video.url }} big={IMAGE_LARGE} />
                let video;
                if (t.video) {
                    video = <video width="200rem" height="200rem" controls >
                        <source src={BASE_URL + t.video.url} type="video/mp4" />
                    </video>
                }

                if (t.image) {
                    return {
                        tableData: [status, image, t.link_url, t.title, t.show_after],
                        id: t.id
                    };
                } else {
                    return {
                        tableData: [status, video, t.link_url, t.title, t.show_after],
                        id: t.id
                    };
                }
            });
        }


        return (
            <div>
                <ConfirmModal
                    open={this.state.confirmModalOpen}
                    close={this.closeConfirmModalHandler}
                    message={this.state.confirmMessage}
                    yesButtonClisk={() => this.deleteHandler(this.state.deleteId)} />
                <CustomModal open={this.state.addModalOpen} close={this.closeAddModal}>
                    <AddAdvertismentsForm onClose={this.closeAddModal} advertId={advertId} title={advertId ? adverts[advertId - 1].name : null} />
                </CustomModal>
                <CustomModal open={this.state.editModalOpen} close={this.closeEditModal}>
                    <EditAdvertismentsForm
                        round={this.state.advert}
                        onClose={this.closeEditModal}
                        advert={this.state.advert}
                        advertId={advertId}
                        title={advertId ? adverts[advertId - 1].name : null}
                    />
                </CustomModal>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>Advertisements:</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer style={{ alignItems: "center", width: "100%", justifyContent: "center" }}>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedSelect
                                            labelName="Type of advertisement"
                                            selectData={mappedAdverts}
                                            value={advertId}
                                            onChange={(event) => this.handleSelect(event)}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer style={{ alignItems: "center", width: "100%", justifyContent: "center" }}>
                                    {advertId ? <GridItem xs={12} sm={6} md={3}>
                                        <Button
                                            round
                                            onClick={() => this.addHandler()}
                                            color="success">
                                            Add {adverts[advertId - 1].name}
                                        </Button>
                                    </GridItem> : null}
                                    {advertId ? <GridItem xs={12} sm={6} md={3}>
                                        <OutlinedSelect
                                            labelName="Advertisement action"
                                            selectData={mappedActions}
                                            value={this.state.actionId}
                                            onChange={(event) => this.handleActionSelect(event)}
                                        />
                                    </GridItem> : null}
                                </GridContainer>
                                <Table
                                    tableHeaderColor="gray"
                                    t tableHead={["Status", "Image/Video", "Link", "Title", "Time"]}
                                    tableData={mappedAdvertisments}
                                    editHandler={this.editHandler}
                                    deleteHandler={this.openConfirmDeleteHandler}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        adverts: state.advertisement.adverts,
        actions: state.advertisement.actions,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllAdverts: () => dispatch(getAllAdverts()),
        getAllActions: () => dispatch(getAllActions()),
        changeAction: (positionId, actionId) => dispatch(changeAction(positionId, actionId)),
        deleteAdvert: (positionId, id) => dispatch(deleteAdvert(positionId, id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdvertismentsList));

import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import List from "@material-ui/icons/List";
// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";

import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx";
import Pagination from "UI/Pagination/Pagination";

import { connect } from 'react-redux';
import { darkBlueColor } from 'utils/Constants'
import { getUserInRoomInfo } from "Pages/GameRooms/actions/gameRooms"

const styles = {

    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    badgeTitle: {
        marginRight: "10px"
    },
    inputIconsColor: {
        color: "lightgray"
    },
};

class UserInRoomInfo extends React.Component {

    state = {
        lastPage: 1,
        page: 1
    }

    componentWillMount() {
        this.filterHandler(1);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user !== this.props.user) {
            this.setState({
                page: nextProps.user.current_page,
                lastPage: nextProps.user.last_page,
            })
        }
    }

    filterHandler = (page) => {
        this.props.getUserInRoomInfo(
            {
                leagueId: this.props.match.params.league_id,
                page: page, roomId: this.props.match.params.room_id,
                userId: this.props.match.params.user_id
            }
        );
    }

    render() {
        const { classes } = this.props;

        let mappedUserGames = [];
        if (this.props.user && this.props.user.data && this.props.user.data.length > 0) {
            mappedUserGames = this.props.user.data.map((t, index) => {
                const startTime = "" + new Date(t.match_date).toLocaleDateString("en-US") + ", " + t.match_time;
                return {
                    tableData: [t.away_team_name, t.awayteam_score + " : " + t.hometeam_score, t.home_team_name, startTime, t.match_shield, t.prediction, t.points],
                    id: index
                };
            });
        }

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="warning" className={classes.card}>
                            <h4 className={classes.cardTitle}>{this.props.match.params.user_name}</h4>
                        </CardHeader>
                        <CardBody>
                            <TableWithActionButtons
                                tableHeaderColor="gray"
                                tableHead={["Away Team Name", "Result", "Home team Name", "Start Time", "Shield", "Prediction", "Points"]}
                                tableData={mappedUserGames}

                            />
                        </CardBody>
                        <GridItem xs={12} sm={12} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {this.state.lastPage > 1 ? <Pagination
                                changePageTo={this.filterHandler}
                                currentPage={this.state.page}
                                totalPages={this.state.lastPage}
                                color="warning"
                            /> : null}
                        </GridItem>
                    </Card>
                </GridItem>
            </GridContainer>
        );

    }
}

const mapStateToProps = state => {
    return {
        user: state.rooms.user
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getUserInRoomInfo: (data) => dispatch(getUserInRoomInfo(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserInRoomInfo));

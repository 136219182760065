import { GET_USERS_EMAILS } from 'Pages/Notifications/actions/actionTypes';


const initialState = {
    usersEmails: [],
    uiUserEmails: []
}

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_USERS_EMAILS:
            return {
                ...state,
                usersEmails: action.data,
                uiUserEmails: action.data.map(t => {return t.email})
            }


        default:
            return state;
    }
}
import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getAllFilteredUsers = (data) => {
    return dispatch => {
        axios.post('/stats/users', data)
            .then(res => {
                dispatch(setAllFilteredUsers(res.data.data));
            });
    };
};

export const setAllFilteredUsers = (data) => {
    return {
        type: actionTypes.GET_ALL_USERS,
        data: data
    };
};

export const getUserRooms = (id, page) => {
    return dispatch => {
        axios.get('user/info/' + id, { params: { page: page } })
            .then(res => {
                dispatch(setUserRooms(res.data.data));
            });
    }
}

export const setUserRooms = (rooms) => {
    return {
        type: actionTypes.GET_USER_ROOMS,
        rooms
    }
}

export const getUserInfo = (id) => {
    return dispatch => {
        axios.get('/users/' + id)
            .then(res => {
                dispatch(setUserInfo(res.data.data));
            });
    }
}

export const setUserInfo = (user) => {
    return {
        type: actionTypes.GET_USER_INFO,
        user
    }
}

export const editUser = (data) => {
    return dispatch => {
        axios.put('/users', data)
    }
}

export const getUserPredictions = (id) => {
    return dispatch => {
        axios.get('/predictions', { params: { user_id: id } })
            .then(res => {
                dispatch(setUserPredictions(res.data.data));
            });
    }
}

export const setUserPredictions = (predictions) => {
    return {
        type: actionTypes.GET_USER_PREDICTIONS,
        predictions
    }
}

export const editUserPrediction = (data) => {
    return dispatch => {
        axios.put('/users/match', data)
        .then(res=> {
            dispatch(setUserPrediction(data));
        });
    }
}


export const setUserPrediction = (data) => {
    return {
        type: actionTypes.EDIT_USER_PREDICTION,
        prediction: data
    }
}
import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getAllGameWeeksForLeague = (id) => {
    return dispatch => {
        axios.get('/leagues/' + id + '/rounds')
            .then(res => {
                dispatch(setAllGameWeeks(res.data.data));
            });
    };
};

export const setAllGameWeeks = (weeks) => {
    return {
        type: actionTypes.GET_GAME_WEEKS,
        weeks
    };
};

export const deleteGameWeek = (id) => {
    return dispatch => {
        axios.delete("rounds/" + id)
            .then(res => {
                dispatch(setGameWeekDeleted(id));
            });
    }
}

export const setGameWeekDeleted = (id) => {
    return {
        type: actionTypes.DELETE_GAME_WEEK,
        id
    }
}

export const addGameWeek = (data) => {
    return dispatch => {
        axios.post('/rounds', data)
            .then(res => {
                if (res.data.has_error === false) {
                    dispatch(setAddedRound(res.data.data));
                }
            });
    };
};

export const setAddedRound = (week) => {
    return {
        type: actionTypes.ADD_GAME_WEEK,
        newWeek: week
    };
};

export const editGameWeek = (data) => {
    return dispatch => {
        axios.put("/rounds/" + data.id, data)
            .then(res => {
                if (res.data.has_error === false) {
                    dispatch(setEditGameWeek(res.data.data));
                }
            });
    }
}

export const setEditGameWeek = (week) => {
    return {
        type: actionTypes.EDIT_GAME_WEEK,
        week: week
    }
}

export const clearGameWeeks = () => {
    return {
        type: actionTypes.CLEAR_GAME_WEEKS
    }
}
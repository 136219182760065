import { AUTH_SET_TOKEN, USER_SET } from "./actionTypes";
import axios from 'utils/axios';

export const tryAuth = authData => {
  return dispatch => {

    axios.post('/auth/login/admin', authData)
      .then(res => {
        axios.defaults.headers.common.Authorization =
          res.headers.authorization;
        localStorage.setItem('token', res.headers.authorization);
        //dispatch(authSetToken(res.headers.authorization));
        dispatch(setUser(res.data.data));
      });
  };
};

export const authSetToken = token => {
  return {
    type: AUTH_SET_TOKEN,
    token: token
  };
};

export const setUser = user => {
  localStorage.setItem('loggedUser', JSON.stringify(user));
  return {
    type: USER_SET,
    user
  };
};